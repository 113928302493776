<template>
  <div class="carousel">
    <Slider
      v-if="photos"
      animation="fade"
      :control-btn="false"
      v-model="sliderValue"
      :duration="5000"
      :speed="3000"
    >
      <SliderItem
        v-for="(photo, index) in photos"
        :key="index"
        @click="changeIndex(1)"
      >
        <img
          :src="
            photo.url
              ? photo.url
              : '../../assets/customer/restaurant/restaurant.png'
          "
          :alt="photo.title ? photo.title : ''"
        />
      </SliderItem>
    </Slider>
    <div class="no-photo" v-else>There's no photo of the shop.</div>
  </div>
</template>
<script>
import { Slider, SliderItem } from "vue-easy-slider";

export default {
  props: {
    photos: [],
  },
  components: {
    Slider,
    SliderItem,
  },
  data() {
    return {
      sliderValue: 2,
    };
  },
  methods: {
    changeIndex(index) {
      this.sliderValue = index;
    },
  },
};
</script>
<style>
.carousel {
  height: 200px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.hooper {
  height: 100%;
  width: 100%;
  outline: none;
}

.slider {
  border-radius: 8px;
  height: 160px !important;
}
.carousel img {
  height: 160px;
  /* height: 100%; */
  max-width: 768px;
  width: calc(100vw - 24px);
  border-radius: 8px;
  object-fit: cover;
}

.hooper-track {
  outline: none;
  width: 100%;
}

.hooper-list {
  height: 100%;
  width: 100%;
}
.hooper-indicator:hover,
.hooper-indicator.is-active {
  background-color: #f0edf2;
  border: 1px solid #412250;
  outline: none;
}
.hooper-indicator {
  background-color: #f0edf2;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
</style>