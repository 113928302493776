import Vue from "vue";

Vue.prototype.global_success = Vue.observable({ message: "" });

Vue.prototype.global_error = Vue.observable({
  message: "",
  show: false,
});

Vue.prototype.global_loading = Vue.observable({
  show: false,
});

// token for customer
Vue.prototype.token = Vue.observable({
  config: {
    headers: {
      Authorization: "Bearer " + localStorage.token,
    },
  },
});

// data of customer
Vue.prototype.customer_addresses = Vue.observable({ data: [] });
Vue.prototype.order_processing = Vue.observable({ data: [] });
Vue.prototype.popular = Vue.observable({ quantity: 0 });
Vue.prototype.selecting_item = Vue.observable({ data: {} });
Vue.prototype.selected_address = Vue.observable({ data: {} });
Vue.prototype.my_location = Vue.observable({ data: {} });
Vue.prototype.customer_items = Vue.observable({ data: {} });
Vue.prototype.customer_categories = Vue.observable({ data: [] });
Vue.prototype.popular_items = Vue.observable({ data: {} });
Vue.prototype.customer_extras = Vue.observable({ data: [] });
Vue.prototype.customer_compulsories = Vue.observable({ data: [] });
Vue.prototype.customer_cards = Vue.observable({ data: [] });
Vue.prototype.customer_orders = Vue.observable({
  data: [],
  has_in_progress: false,
});

Vue.prototype.cities = Vue.observable([
  {
    id: 2,
    title: "Espoo",
  },
  {
    id: 9,
    title: "Hämeenlinna",
  },
  {
    id: 1,
    title: "Helsinki",
  },
  {
    id: 19,
    title: "Hyvinkää",
  },
  {
    id: 12,
    title: "Joensuu",
  },
  {
    id: 6,
    title: "Jyväskylä",
  },
  {
    id: 18,
    title: "Lahti",
  },
  {
    id: 15,
    title: "Lempäälä",
  },
  {
    id: 11,
    title: "Lohja",
  },
  {
    id: 16,
    title: "Raisio",
  },
  {
    id: 10,
    title: "Rovaniemi",
  },
  {
    id: 17,
    title: "Seinäjoki",
  },
  {
    id: 3,
    title: "Tampere",
  },
  {
    id: 14,
    title: "Turenki",
  },
  {
    id: 5,
    title: "Turku",
  },
  {
    id: 4,
    title: "Vantaa",
  },
]);

Vue.prototype.user_signup = Vue.observable({
  name: "",
  email: "",
  phone_number: "",
  password: "",
  expired_at: "",
});

Vue.prototype.blur_background = Vue.observable({
  show: false,
});

export default Vue.mixin({
  data() {
    return {};
  },
});
