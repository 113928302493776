<template>
  <div class="search-page">
    <div class="header">
      <div class="container">
        <div class="search-btn">
          <select v-model="city" @change="setCity" name="city" id="city">
            <option
              v-for="the_city in cities.sort()"
              :key="the_city.id"
              :value="the_city"
            >
              {{ the_city.title }}
            </option>
          </select>
          <div class="icon location"></div>
        </div>
        <h4 class="cancel" @click="closeSearch">
          {{ $t("customer_home.cancel") }}
        </h4>
      </div>
      <!-- search box codes -->
      <div class="search">
        <div class="icon"></div>
        <input
          v-model="keyword"
          @input="search"
          type="text"
          name="search"
          id="search-box"
          :placeholder="
            type
              ? $t('customer_home.search_restaurant')
              : $t('customer_home.search_food')
          "
        />
      </div>
      <!-- end of search box -->

      <!-- switcher codes -->
      <switcher
        :first_option="$t('customer_home.search_restaurant')"
        :second_option="$t('customer_home.search_food')"
        @selected_option="SelectedOption"
      ></switcher>
      <!-- end of switcher codes -->
      <!-- filters -->
     
      <!-- end filters -->
    </div>
    <div v-if="advertisement" class="content advertisement">
      <advertisement-card :restaurant="advertisement"></advertisement-card>
    </div>
    <div class="content" :id="advertisement ? '' : 'padding_top'">
      <restaurant-card
        v-for="(restaurant, index) in restaurants"
        :key="index"
        :restaurant="restaurant"
        v-if="restaurant.activated == 1"
      ></restaurant-card>
      <empty-search v-if="restaurants.length == 0"></empty-search>
    </div>
    <div id="blur_background" class="blur-bg">
      <!-- <nut-filter-modal
        @maximum="setMaximum"
        @minimum="setMinimum"
      ></nut-filter-modal> -->
    </div>
  </div>
</template>
<script>
import AdvertisementCard from "./AdvertisementCard.vue";
import NutFilterModal from "./NutFilterModal.vue";
import RestaurantCard from "./RestaurantCard.vue";
import Switcher from "./Switcher.vue";
import EmptySearch from "./EmptySearch.vue";

export default {
  components: {
    Switcher,
    RestaurantCard,
    AdvertisementCard,
    NutFilterModal,
    EmptySearch,
  },
  mounted() {
    document.getElementById("search-box").focus();
    this.fetchAdvertise();

    this.city = JSON.parse(localStorage.city);
  },
  methods: {
    closeSearch() {
      this.$emit("selected_city", this.city);

      this.$emit("search_page", false);
    },
    setMaximum(val) {
      this.maximum_calorie = val;
    },
    setMinimum(val) {
      this.search();
      this.minimum_calorie = val;
    },
    setCity() {
      this.fetchAdvertise();

      this.city_id = this.city.id;
      localStorage.setItem("city", JSON.stringify(this.city));
    },
    SelectedOption(val) {
      this.type = val; // true=restaurant, false=food
      let advertisement = document.querySelector(".advertisement");
      if (this.advertisement) {
        if (this.type) {
          advertisement.style.marginTop = "168px";
        } else {
          advertisement.style.marginTop = "226px";
        }
      }
    },
    search() {
      this.$http
        .get(
          this.$hostname +
            "/search?type=" +
            (this.type ? 1 : 0) +
            "&keyword=" +
            this.keyword +
            "&city=" +
            this.city_id +
            "&maximum_calorie=" +
            this.maximum_calorie +
            "&minimum_calorie=" +
            this.minimum_calorie +
            "&vegan=" +
            (this.vegan ? 1 : 0) +
            "&gluten=" +
            (this.gluten ? 1 : 0) +
            "&lactose=" +
            (this.lactose ? 1 : 0)
        )
        .then((resp) => {
          this.restaurants = resp.data.restaurants;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    fetchAdvertise() {
      this.$http
        .get(this.$hostname + "/advertisement?city=" + this.city_id)
        .then((resp) => {
          this.advertisement = resp.data.advertisement[0];
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
  },
  data() {
    return {
      type: true,
      keyword: "",
      restaurants: [],
      advertisement: {},
      vegan: false,
      gluten: false,
      lactose: false,
      maximum_calorie: "",
      minimum_calorie: "",
      city: {},
    };
  },
  props: {
    city_id: 0,
  },
};
</script>
<style scoped>
.search-page {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  z-index: 995;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.container {
  justify-content: space-between;
  align-items: baseline;
  border: none;
  padding: 0;
}
.search {
  background-color: #f5f6f7;
  border-radius: 8px;
  display: flex;
  align-items: center;
  height: 40px;
  margin: auto;
  margin-bottom: 8px;
  max-width: 768px;
}
#padding_top {
  padding-top: 175px;
}
.content {
  gap: 12px;
  padding: 0 16px 80px;
  overflow-y: scroll;
}

.location {
  width: 20px;
  position: absolute;
  top: 4px;
  left: 4px;
  background-image: url("../../assets/customer/restaurant/location.svg");
}

.advertisement {
  background-color: #f0edf2;
  width: 100%;
  margin: 168px auto 0px;
  min-height: 142px;
  height: 218px;
  padding: 16px;
  transition: 0.3s ease;
  z-index: 988;
}

.filters {
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  max-width: 768px;
}

.options {
  width: 90%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
}

.options label {
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid #ffaa9960;
  width: 31%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #737373;
  font-weight: 400;
  font-size: 14px;
}

.options input[type="checkbox"]:checked + label {
  border: 1px solid #ff6a4d;
  color: #ff6a4d;
}
.options input[type="checkbox"] {
  appearance: none;
  display: none;
  background-image: none;
}

.filter {
  background-image: url("../../assets/customer/restaurant/filter.svg");
  width: 40px;
  height: 40px;
  background-size: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ffeeeb;
  border-radius: 8px;
}
.search input[type="text"] {
  width: 100%;
}

.search-btn {
  position: relative;
}
.cancel {
  cursor: pointer;
}
select {
  width: 100px;
  cursor: pointer;
  height: 40px;
  text-align: right;
  padding: 10px;
  background-color: #f5f6f7;
  border-radius: 8px;
  margin-bottom: 12px;
  color: #4d4d4d;
  font-weight: 400;
  font-size: 14px;
}
</style>
