import Vue from 'vue'

// set global axios
import Axios from 'axios'

window.Pusher = require('pusher-js')

Vue.prototype.$http = Axios

// local address connect to server
// Vue.prototype.$hostname = 'http://192.168.12.177:8000/api'
// Vue.prototype.$public_server = 'http://192.168.12.177:8000'


// aws address connect to server
Vue.prototype.$hostname = 'https://backend.nordantia.com/api'
Vue.prototype.$public_server = 'https://backend.nordantia.com'


// for pusher server
Vue.prototype.$pusher = new Pusher('8cfcafdaa3e6ed561c97', {
  cluster: 'ap1',
})

//for self-hosted socket local
// Vue.prototype.$pusher = new Pusher('ABCDEFG', {
//   cluster: 'ap1',
//   forceTLS: false,
//   httpHost: '127.0.0.1',
//   httpPort: 6001,
//   statsHost: '127.0.0.1',
//   httpPath: '/',
//   wsHost: '127.0.0.1',
//   wsPort: 6001,
//   auth: {
//     headers: {
//       Authorization: 'Bearer ' + localStorage.token,
//     },
//   },
// })

export default Vue.mixin({
  data() {
    return {}
  },
})
