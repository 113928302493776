export default {
  dishes: {},
  dish: null,
  cart: localStorage.cart ? JSON.parse(localStorage.cart) : [],
  checkout: localStorage.checkout ? localStorage.checkout : false,
  total: localStorage.total ? localStorage.total : 0,
  order_type: 0, // type:1 => restaurant, type:2 => take away
  table: '',
  payment_method: 3, //  type:1 => online banking, type:2 =>credit card , type:3 => cash, type:4 => mobilepay
  comment: localStorage.comment ? localStorage.comment : '',
  auth: localStorage.auth ? localStorage.auth : false,
  shop_id: localStorage.shop_id ? localStorage.shop_id : '',
  order_id: null,
  restaurant: '',
  name: '',
  email: '',
  token: null,
  user: localStorage.your_identifier, // just for restaurant
}
