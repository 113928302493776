export const CREAT_NEW_CART = (state, { basket }) => {
  state.cart = basket;

  localStorage.cart = JSON.stringify(state.cart);

  calcTotal(state);

  return;
};

export const ADD_TO_CART = (state, { item }) => {
  let cards = [];
  let prices = [];

  localStorage.checkout = true;
  state.checkout = true;

  state.cart.push({
    item,
    cards,
    prices,
  });

  localStorage.cart = JSON.stringify(state.cart);

  calcTotal(state);

  return;
};

export const ADD_PRICES_TO_CART = (state, { dish, index, prices }) => {
  let tmp_prices = prices.slice();

  for (let i = 0; i < state.cart.length; i++) {
    if (i == index) {
      state.cart[i].prices = tmp_prices;

      localStorage.cart = JSON.stringify(state.cart);

      calcTotal(state);

      return;
    }
  }
};

export const ADD_CARD_TO_CART = (state, { dish, index, card }) => {
  for (let i = 0; i < state.cart.length; i++) {
    if (i == index) {
      for (let j = 0; j <= state.cart[index].cards.length; j++) {
        if (
          state.cart[index].cards[j] &&
          state.cart[index].cards[j].id == card.id
        ) {
          state.cart[index].cards.splice(j, 1);
          localStorage.cart = JSON.stringify(state.cart);

          calcTotal(state);

          return;
        }
      }

      state.cart[i].cards.push(card);
      localStorage.cart = JSON.stringify(state.cart);

      calcTotal(state);

      return;
    }
  }
};

export const ADD_DELIVERY_CHARGE = (state, { delivery_charge }) => {
  let total = 0;
  state.cart.forEach((element) => {
    // if (element.item.discount > 0) {
    //   total +=
    //     element.item.price - (element.item.price * element.item.discount) / 100;
    // }
    if (element.cards.length) {
      element.cards.forEach((card) => {
        total += card.price;
      });
    }

    if (element.prices.length) {
      element.prices.forEach((price) => {
        total += price.price - (price.price * element.item.discount) / 100;
      });
    }
  });

  if (
    total >= parseFloat(localStorage.minimum_order_free_delivery) &&
    localStorage.free_delivery_option == 1
  ) {
    delivery_charge = 0;
  }

  total += parseFloat(delivery_charge);

  localStorage.total = total.toFixed(2).toString().replace(".", ",") + " €";

  state.total = total.toFixed(2).toString().replace(".", ",") + " €";

  return;
};

export const ADD_DISCOUNT_TO_CART = (state, { discount }) => {
  let total = 0;
  state.cart.forEach((element) => {
    if (element.item.discount > 0) {
      total +=
        element.item.price - (element.item.price * element.item.discount) / 100;
    } else {
      total += element.item.price;
    }
    if (element.cards.length) {
      element.cards.forEach((card) => {
        total += card.price;
      });
    }

    if (element.prices.length) {
      element.prices.forEach((price) => {
        total += price.price;
      });
    }
  });

  total = total - (total * discount) / 100;

  total += parseFloat(
    localStorage.delivery_charge && localStorage.order_type == 3
      ? localStorage.delivery_charge -
          (localStorage.delivery_charge * discount) / 100
      : 0
  );

  localStorage.total = total.toFixed(2).toString().replace(".", ",") + " €";

  state.total = total.toFixed(2).toString().replace(".", ",") + " €";
};

export const REMOVE_FROM_CART = (state, { item, index }) => {
  if (index) {
    localStorage.checkout = true;
    state.checkout = true;

    state.cart.splice(index, 1);

    localStorage.cart = JSON.stringify(state.cart);

    calcTotal(state);

    return;
  } else {
    let index = 0;

    for (index; index < state.cart.length; index++) {
      if (state.cart[index].item.id === item.id) {
        localStorage.checkout = true;
        state.checkout = true;

        state.cart.splice(index, 1);

        localStorage.cart = JSON.stringify(state.cart);

        calcTotal(state);

        return;
      }
    }
  }
};

export const CLEAR_CART = (state) => {
  state.cart = [];
  localStorage.cart = [];
  state.comment = "";
  state.total = 0;
  localStorage.total = 0;
  localStorage.removeItem("comment");
  localStorage.checkout = false;
  state.checkout = false;
};

export const SET_ORDER_TYPE = (state, { type }) => {
  state.order_type = type;
};

export const SET_TABLE = (state, { table }) => {
  state.table = table;
};

export const SET_PAYMENT_METHOD = (state, { payment_method }) => {
  state.payment_method = payment_method;
};

export const SET_COMMENT = (state, { comment }) => {
  state.comment = comment;
};

export const LOGIN = (state, { auth }) => {
  state.auth = auth;
};

export const SET_QR = (state, { qr }) => {
  state.qr = qr;
};

export const SET_ORDER_ID = (state, { order_id }) => {
  state.order_id = order_id;
};

export const SET_RESTAURANT_NAME = (state, { restaurant }) => {
  state.restaurant = restaurant;
};

export const SET_NAME = (state, { name }) => {
  state.name = name;
};

export const SET_EMAIL = (state, { email }) => {
  state.email = email;
};

export const SET_TOKEN = (state, { token }) => {
  state.token = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
};

export const SET_CART_FROM_LOCAL = (state, { cart }) => {
  state.cart = cart;
};

// just for restaurant
export const SET_USER_ID = (state, { user }) => {
  state.user = user;
};

function calcTotal(state) {
  let total = 0;
  state.cart.forEach((element) => {
    // if (element.item.discount > 0) {
    //   total +=
    //     element.item.price - (element.item.price * element.item.discount) / 100;
    // }
    if (element.cards.length) {
      element.cards.forEach((card) => {
        total += card.price;
      });
    }

    if (element.prices.length) {
      element.prices.forEach((price) => {
        total += price.price - (price.price * element.item.discount) / 100;
      });
    }
  });

  localStorage.total = total.toFixed(2).toString().replace(".", ",") + " €";

  state.total = total.toFixed(2).toString().replace(".", ",") + " €";

  // return
}
