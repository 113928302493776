<template>
  <div class="order">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/history'"></back-button>

        <div class="title">
          {{
            order.status == "done"
              ? $t("order.order_details")
              : $t("order.order_status")
          }}
        </div>
        <span></span>
      </div>
    </div>
    <div class="content">
      <order-card :order="order"></order-card>
      <div
        v-if="order.status !== 'done' || order.payment_status == 0"
        class="btn status"
      >
        <div class="progress">
          <div
            :class="[
              order.progress_status == 'coming' ? 'complete_bar' : '',
              'bar',
            ]"
          ></div>
          <div class="step">
            <div
              :class="[
                ['accepted', 'coming'].includes(order.progress_status)
                  ? 'on'
                  : 'off',
                'circle',
              ]"
            >
              1
            </div>
          </div>

          <div class="step">
            <div
              :class="[
                ['coming'].includes(order.progress_status) ? 'on' : 'off',
                'circle',
              ]"
            >
              2
            </div>
          </div>
        </div>
        <div class="progress">
          <div
            :class="[
              ['accepted', 'coming'].includes(order.progress_status)
                ? 'caption-on'
                : '',
              'caption',
            ]"
          >
            {{ $t("cart.accepted") }}
          </div>

          <div
            :class="[
              ['coming'].includes(order.progress_status) ? 'caption-on' : '',
              'caption',
            ]"
          >
            {{ $t("cart.coming") }}
          </div>
        </div>

        <div v-if="order.order_type == 'delivery'" class="delivery">
          <div class="desc">{{ $t("order.delivery_time") }}</div>
          <counter v-if="time_over" :end="order.delivered_at"></counter>
        </div>
        <div v-if="order.order_type == 'delivery'" class="delivery">
          <div class="desc">{{ $t("order.delivery_fee") }}</div>
          <div class="method">{{ formatPrice(order.delivery_charge) }}</div>
        </div>

        <div class="payment">
          <div class="desc">{{ $t("order.payment_method") }}</div>
          <div class="method">
            {{
              order.payment_method == "online"
                ? $t("cart.online_payment")
                : order.payment_method == "cash"
                ? $t("cart.cash_card")
                : "Not defined"
            }}

            <span :class="[order.payment_status == 1 ? 'paid' : 'unpaid']">
              {{ order.payment_status == 1 ? "(" + $t("cart.paid") + ")" : "" }}
            </span>
          </div>
        </div>
      </div>
      <div class="btn items">
        <div class="dish" v-for="(dish, index) in products" :key="index">
          <div class="item">
            <div
              class="photo"
              :style="[
                dish.item.photo
                  ? {
                      'background-image': 'url(' + dish.item.photo + ')',
                    }
                  : '',
              ]"
            ></div>
            <div class="detail">
              <div class="head">
                <h3>
                  {{ dish.item.title }}
                </h3>
              </div>
              <div class="footer"></div>
            </div>
          </div>

          <div class="extra" v-for="extra in dish.prices" :key="extra.id">
            <div class="caption">
              {{
                (extra.title ? extra.title : "") + "(" + extra.description + ")"
              }}
            </div>
            <div class="info">
              <div class="quantity">x1</div>
              <div class="price" v-if="dish.item.discount > 0">
                <s>{{ formatPrice(extra.price) }}</s>
                &nbsp;
                {{
                  formatPrice(
                    extra.price - (extra.price * dish.item.discount) / 100
                  )
                }}
              </div>
              <div v-else class="price">
                {{ formatPrice(extra.price) }}
              </div>
            </div>
          </div>

          <div v-for="drink in dish.cards" :key="drink.id" class="extra">
            <div class="caption">{{ drink.title }}</div>
            <div class="info">
              <div class="quantity">x1</div>
              <div class="price">{{ formatPrice(drink.price) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <rate-order v-if="!order.rate && authed" :order="order"></rate-order> -->
    <div id="blur_background" class="blur-bg">
      <rate-modal></rate-modal>
      <succeed-modal :message="global_success.message"></succeed-modal>
      <payment-terms-modal></payment-terms-modal>
    </div>
  </div>
</template>
<script>
import BackButton from "../components/common/BackButton.vue";
import Counter from "../components/common/Counter.vue";
import OrderCard from "../components/customer/OrderCard.vue";
import RateModal from "../components/customer/RateModal.vue";
import RateOrder from "../components/customer/RateOrder.vue";
import SucceedModal from "../components/customer/SucceedModal.vue";
import PaymentTermsModal from "../components/customer/PaymentTermsModal.vue";

export default {
  mounted() {
    this.successfullPaymentAndSubmit();

    if (localStorage.token) {
      this.authed = true;
    }

    Pusher.logToConsole = false;
    const vm = this;

    let channel = this.$pusher.subscribe("order-" + this.id);

    channel.bind("orderStatus", function (data) {
      if (data) {
        vm.fetch();
      }
    });

    this.fetch();
  },
  computed: {
    products() {
      if (this.order.json_items) {
        return JSON.parse(this.order.json_items);
      }
    },
  },
  components: {
    BackButton,
    OrderCard,
    Counter,
    RateOrder,
    RateModal,
    SucceedModal,
    PaymentTermsModal,
  },
  methods: {
    fetch() {
      this.global_loading.show = true;
      this.$http
        .get(
          this.$hostname + "/client/shops/orders/" + this.id,
          this.token.config
        )
        .then((resp) => {
          this.successRequest();

          this.order = resp.data.order;
          console.log(resp.data.order.id);

          if (resp.data.order.status == "rejected") {
            this.global_error.show = true;
            this.global_error.message = resp.data.order[0].reject_reason;

            // setTimeout(() => {
            //   this.$router.push("/customer/history");
            // }, 5000);
          }
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    payNow() {
      if (this.order.payment_status == 0) {
        if (this.order.payment_method == "online") {
          if (!localStorage.token && !this.local_email) {
            this.global_error.show = true;
            this.global_error.message = "Please enter your email address!";
          } else {
            this.redirectToGateway();
          }
        } else {
          this.callWaiterApi();
        }
      }
    },
    callWaiterApi() {
      let data = {
        table: this.order.table_number,
        qr: this.order.restaurant.menu.qr_url,
        type: "pay",
        total: this.totalPrice(this.order.json_items),
        order_id: this.order.id,
      };
      this.global_loading.show = true;
      this.$http
        .post(this.$hostname + "/call-waiter", data)
        .then((resp) => {
          this.successRequest();
          this.showModal("succeed_modal");
          this.global_success.message = this.$t("call.coming");

          setTimeout(() => {
            this.closeModal("succeed_modal");
          }, 3000);
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    timeOver(val) {
      this.time_over = val;
    },
    successfullPaymentAndSubmit() {
      if (this.$route.query.transaction_number && this.isItiOS()) {
        window.location =
          "com.nordantia.tilaa://nordantia/checkout?orderId=" + this.id;
      }

      if (this.$route.query.payment_status == 1) {
        this.setOrderInLocalStorage();
        this.done();
      }
    },
    setOrderInLocalStorage() {
      if (localStorage.orders) {
        this.orders.push(localStorage.orders);
      }
      this.orders.push(parseInt(this.id));

      localStorage.setItem("orders", this.orders);
    },
    done() {
      this.clearStorage();

      this.$store.dispatch("clearCart");

      this.global_success.message = this.$t("cart.success_order");

      this.showModal("succeed_modal");

      setTimeout(() => {
        this.closeModal("succeed_modal");
      }, 3000);
    },
  },
  props: {
    id: 0,
  },
  data() {
    return {
      order: {},
      orders: [],
      time_over: false,
      authed: false,
      local_email: "",
    };
  },
};
</script>
<style scoped>
.order {
  overflow: hidden;
  max-height: 100vh;
}

.container {
  justify-content: space-between;
}
.header {
  z-index: 100;
}
.content {
  gap: 16px;
  height: 100vh;
  padding: 70px 16px 110px;
  overflow-y: scroll;
}

.status {
  flex-direction: column;
  padding: 12px;
  gap: 12px;
}
.progress {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 18px;
}
.bar {
  position: absolute;
  height: 4px;
  width: 87%;
  background-color: #f0edf2;
  max-width: 645px;
  transition: 0.3s ease;
  top: 45%;
  margin: auto;
  left: 7%;
}
.half_bar {
  transition: 3s ease;
  background: linear-gradient(
    to right,
    #412250 0%,
    #412250 50%,
    #f0edf2 50%,
    #f0edf2 100%
  );
}
.complete_bar {
  transition: 3s ease;
  background: linear-gradient(
    to right,
    #412250 0%,
    #412250 100%,
    #f0edf2 0%,
    #f0edf2 0%
  );
}
.circle {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.on {
  background-color: #412250;
}

.off {
  background-color: #aa98b3;
}
.circle span {
  position: absolute;
  z-index: 99;
}
.caption {
  color: #aa98b3;
  display: flex;
  width: 68px;
  align-items: center;
  justify-content: center;
}
.caption-on {
  color: #412250;
}
.time,
.delivery,
.payment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.desc {
  font-weight: 300;
  font-size: 14px;
  color: #737373;
}
.method {
  font-size: 14px;
  text-align: right;
  color: #776282;
}
.paid {
  color: #48e639;
}
.unpaid {
  color: #ff6a4d;
  cursor: pointer;
  text-decoration: underline;
}
.items {
  flex-direction: column;
}

.dish {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #f0edf2;
  padding: 12px;
  width: 100%;
  gap: 12px;
}

.item {
  display: flex;
  gap: 12px;
}

h3 {
  font-size: 18px;
  line-height: 21px;
  color: #4d4d4d;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

.detail {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 64%;
  width: 70%;
  height: 100%;
}

.photo {
  min-width: 86px;
  width: 30%;
  height: 50px;
  border-radius: 8px;
  background-image: url("../assets/customer/menu/item.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.head {
  display: flex;
  justify-content: flex-start;
  gap: 4px;
  align-items: center;
  width: 100%;
}
.footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.price,
.quantity {
  color: #776282;
  font-size: 14px;
}
.detail span {
  font-weight: 300;
  font-size: 12px;
  color: #b3b3b3;
}
.footer .quantity,
.extras .caption {
  font-weight: 300;
  font-size: 14px;
  color: #737373;
}

.extras .caption {
  width: 50%;
  justify-content: flex-start;
}

.compulsory .caption {
  padding-left: 5px;
}
.info {
  display: flex;
  gap: 20px;
}
.extras {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
}
.extra {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.extra .caption {
  justify-content: flex-start;
  width: 50%;
}
.email input {
  background-color: #ffffff;
  border-radius: 8px;
  height: 32px;
  width: 75%;
  padding: 0 8px;
}
.email .input {
  background-color: #ffffff;
  padding: 4px 0px;
}

.email .note {
  padding: 0;
  margin: 8px 0;
}

.email {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.visma {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4px 0;
  text-decoration: underline;
}

.note {
  font-size: 14px;
  padding: 0 8px;
  color: #ff6a4d;
}
</style>
