var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu"},[_c('div',{ref:"headRef",staticClass:"header",attrs:{"id":"menu_header"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"title-container"},[_c('div',{staticClass:"title",attrs:{"id":"menu_title"}},[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"actions"},[_c('button',{staticClass:"icon info",on:{"click":_vm.openResturant}})])]),_c('categories',{attrs:{"id":"menu_category","all_items":_vm.all_items,"popular_items":_vm.popular_items,"categories":_vm.categories}})],1),_c('div',{staticClass:"content",attrs:{"id":"menu_content"}},[_c('menu-banner',{attrs:{"restaurant":_vm.restaurant,"rate":_vm.rate,"status":_vm.status,"reviews":_vm.reviews,"photo":_vm.restaurant_photo[0]}}),_c('div',{staticClass:"detail"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"type"},[_vm._v(_vm._s(_vm.restaurant.type))])])]),_c('div',{staticClass:"row"},[(_vm.restaurant.banner_text)?_c('div',{staticClass:"banner_text"},[_vm._v(" "+_vm._s(_vm.restaurant.banner_text)+" ")]):_vm._e(),_c('div',{staticClass:"rate"},[_c('div',{staticClass:"icon star"}),_c('span',[_vm._v(" "+_vm._s(_vm.rate + "/5")+" ")]),_c('span',{staticClass:"review"},[_vm._v("("+_vm._s(_vm.reviews)+")")])])]),_c('div',{staticClass:"big_row"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"working-hours"},[_c('div',{staticClass:"icon clock"}),_c('div',{staticClass:"hours"},[_vm._v(_vm._s(_vm.today_start)+" - "+_vm._s(_vm.today_end))])]),_c('div',{staticClass:"working-hours"},[_vm._v(" "+_vm._s(_vm.$t("menu.working_hours"))+" ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"head"},[_vm._v(_vm._s(_vm.$t("menu.availabe_for")))]),_c('div',{staticClass:"serves"},[_c('div',{class:[
                _vm.restaurant.cash_pickup == 0 && _vm.restaurant.online_pickup == 0
                  ? 'off-serve'
                  : '',
                'serve' ]},[_c('div',{staticClass:"icon"}),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.$t("menu.pick_up")))])]),_c('div',{class:[
                _vm.restaurant.cash_delivery == 0 &&
                _vm.restaurant.online_delivery == 0
                  ? 'off-serve'
                  : '',
                'serve' ]},[_c('div',{staticClass:"icon"}),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.$t("menu.delivery")))])])])])])]),_c('categories',{attrs:{"all_items":_vm.all_items,"popular_items":_vm.popular_items,"categories":_vm.categories}}),_c('items',{attrs:{"id":"menu_items","make_order":_vm.make_order},on:{"selected_item":_vm.selectedItem}})],1),_c('div',{staticClass:"blur-bg",attrs:{"id":"blur_background"}},[_c('succeed-modal',{attrs:{"message":_vm.global_success.message}}),_c('restaurant-info-modal',{attrs:{"restaurant":_vm.restaurant}}),_c('extras-modal',{attrs:{"index":_vm.$store.state.cart.length - 1,"item":_vm.selecting_item.data}}),_c('clear-basket-modal')],1),(_vm.make_order == 1)?_c('continue'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }