var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal",attrs:{"id":"extras_modal"}},[_c('div',{staticClass:"extras"},[_c('div',{staticClass:"fixed"},[(_vm.item.photo)?_c('div',{staticClass:"banner",style:({
          'background-image': 'url(' + _vm.item.photo + ')',
        })},[_c('button',{staticClass:"icon close",on:{"click":function($event){return _vm.closeClearModal()}}})]):_vm._e(),(_vm.item.prices)?_c('div',{staticClass:"head"},[_c('div',{staticClass:"title"},[(!_vm.item.photo)?_c('button',{staticClass:"icon close",on:{"click":function($event){return _vm.closeClearModal()}}}):_vm._e(),(_vm.item.discount > 0)?_c('div',{staticClass:"discount"},[_vm._v(" "+_vm._s("-" + _vm.item.discount + "%")+" ")]):_vm._e(),_c('h4',[_vm._v(_vm._s(_vm.item.title))])]),(_vm.item.discount > 0)?_c('div',{staticClass:"price"},[_c('s',[_vm._v(_vm._s(_vm.formatPrice(_vm.item.prices[0].price)))]),_vm._v("   "+_vm._s(_vm.formatPrice( _vm.item.prices[0].price - (_vm.item.prices[0].price * _vm.item.discount) / 100 ))+" ")]):_c('div',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.item.prices[0].price))+" ")])]):_vm._e(),(_vm.item.description_en || _vm.item.description_fi)?_c('div',{staticClass:"ingredients",domProps:{"innerHTML":_vm._s(
          _vm.$i18n.locale == 'en' ? _vm.item.description_en : _vm.item.description_fi
        )}}):_vm._e()]),_c('div',{staticClass:"scroll",attrs:{"id":"scroll_"}},[_c('div',{staticClass:"compulsories"},[_c('div',{staticClass:"sub-compulsories",attrs:{"id":'compulsories-' + _vm.index}},_vm._l((_vm.item.prices),function(price,index){return _c('div',{key:index,class:[
              'sub-compulsory',
              _vm.isSelectedPrice(price)
                ? 'active_sub_compulsory'
                : _vm.product.prices.length
                ? 'off_compulsory'
                : '' ],on:{"click":function($event){$event.preventDefault();return _vm.addCompulsoryToCart(_vm.item, price)}}},[_c('div',{staticClass:"option"},[_c('label',{attrs:{"for":'sub_compulsory_' + price.id}},[_vm._v(" "+_vm._s((price.title ? price.title + ": " : "") + " " + price.description))]),_c('input',{attrs:{"type":"radio","name":'compulsory_' + price.id,"id":'sub_compulsory_' + price.id}})]),(_vm.item.discount > 0)?_c('div',{staticClass:"price"},[_c('s',[_vm._v(_vm._s(_vm.formatPrice(price.price)))]),_vm._v("   "+_vm._s(_vm.formatPrice(price.price - (price.price * _vm.item.discount) / 100))+" ")]):_c('div',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm.formatPrice(price.price))+" ")])])}),0)])])]),_c('button',{class:[_vm.completeItem ? '' : 'deactive', 'fill'],on:{"click":_vm.done}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("customer_home.add_basket"))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.total_price_item > 0 ? _vm.formatPrice(_vm.total_price_item - (_vm.total_price_item * _vm.item.discount) / 100) : _vm.formatPrice(0))+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }