<template>
  <div @click="next" :class="[checkout ? 'checkout' : 'checkout_not']">
    <div class="content">
      <div class="cart">
        <img src="../../assets/customer/restaurant/basket.svg" width="24" height="24"/>
        {{ restaurantName }}</div>

      <div class="price">
        <span>
          {{ total }}
        </span>
        <div class="icon"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Continue",
  methods: {
    next() {
      this.$router.push("/basket");
    },
  },
  computed: {
    checkout() {
      if (this.totalInBasket() > 0) {
        return this.$store.state.checkout;
      }
    },
    total() {
      return this.$store.state.total;
    },
    restaurantName() {
      return localStorage.restaurant_name;
    },
  },
};
</script>
<style scoped>
.checkout {
  display: flex;
  align-items: flex-end;
  position: fixed;
  width: 100%;
  bottom: 66px;
  z-index: 980;
  transition: 0.2s ease;
  padding: 0 16px;
  cursor: pointer;
}
.checkout_not {
  display: flex;
  align-items: flex-end;
  width: 100%;
  position: fixed;
  bottom: -50px;
  transition: 0.2s ease;
}
.checkout_not .content,
.checkout .content {
  background-color: #b11e88;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  max-width: 768px;
  width: 100%;
  border-radius: 8px;
  height: 48px;
  padding: 10px;
  color: #fafafa;
}
.checkout_not .content .price,
.checkout .content .price {
  display: flex;
  align-items: center;
  height: 100%;
}
.cart {
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.price .icon {
  width: 28px;
  background-position: right;
  background-size: 28px;
  background-image: url("../../assets/customer/menu/right_w.svg");
}
</style>