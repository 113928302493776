<template>
  <div class="main" id="app">
    <error-box></error-box>

    <router-view></router-view>

    <navbar v-if="customer_routes.includes($route.name)"></navbar>

    <div v-if="global_loading.show" class="loading"></div>
  </div>
</template>

<script>
import Navbar from "./components/customer/Navbar.vue";
import ErrorBox from "./components/common/ErrorBox.vue";

export default {
  name: "App",
  mounted() {
    // pass the token to the app
    this.openInstalliOSApp();

    // set cart from local storage
    // if (localStorage.cart) {
    //   this.$store.dispatch("setCartFromLocal", {
    //     cart: JSON.parse(localStorage.cart),
    //   });
    // }

    // set language from local storage
    if (localStorage.language) {
      this.$i18n.locale = localStorage.language;
    }

    // this.whereAreYou();
  },
  components: {
    Navbar,
    ErrorBox,
  },
  data: () => ({
    customer_routes: [
      "Home",
      "Reservation",
      "ReservationPage",
      "History",
      "Account",
      "Profile",
      "Favorites",
      "Addresses",
      "ChangePassword",
      "Scanner",
      "Shop",
      "Basket",
      "Checkout",
      "Order",
    ],
  }),
};
</script>

<style>
* {
  font-family: roboto;
  user-select: none;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  overscroll-behavior-y: contain;
  background-color: #ffffff;
  z-index: 1;
}
.main {
  transition: transform ease 0.2s;
  transform-origin: center bottom;
}
.header {
  position: fixed;
  z-index: 500;
  top: 0%;
  width: 100%;
  background: #ffffff;
  padding: 16px 16px 0;
}

.header .container {
  padding-bottom: 12px;
  border-bottom: 1px solid #f0edf2;
  max-width: 768px;
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.header .container .title {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
}
.header span {
  width: 40px;
}
.icon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px;
  width: 100%;
  height: 24px;
}

.deactive-text {
  color: #737373;
}
.content {
  padding: 87px 16px 72px;
  max-width: 768px;
  margin: auto;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.active-text,
a:active {
  color: #5a187a;
}

.title {
  color: #4d4d4d;
}

button,
input,
select,
textarea {
  outline: none;
}

textarea {
  resize: none !important;
}

a {
  text-decoration: none;
}

h4 {
  color: #4d4d4d;
  font-size: 16px;
  font-weight: normal;
}
h3 {
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #737373;
}
.content .btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f6f7;
  border-radius: 8px;
  padding: 4px;
}

.btn .left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 80%;
  gap: 8px;
}

.btn .left .icon {
  width: 40px;
  height: 40px;
  border-radius: 8px;
}

.btn .left .icon {
  background-color: #f0edf2;
}

.btn .arrow {
  background-image: url("./assets/common/right.svg");
  width: 24px;
}

.bike {
  background-image: url("./assets/customer/restaurant/bike.svg");
  width: 16px;
  height: 16px;
  background-size: 16px;
}

.free-bike {
  background-image: url("./assets/customer/restaurant/free_bike.svg");
  width: 16px;
  height: 16px;
  background-size: 16px;
}

.no-bike {
  background-image: url("./assets/customer/restaurant/no_bike.svg");
  width: 16px;
  height: 16px;
  background-size: 16px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input {
  background: #f8f9fa;
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: 12px 0px;
  position: relative;
}

.input label {
  font-size: 16px;
  line-height: 19px;
  min-width: fit-content;
  border-right: 1px solid #b3b3b3;
  padding: 0px 12px;
}
.input input {
  width: 100%;
}
.input .deactive {
  color: #b3b3b3;
  -webkit-text-fill-color: #b3b3b3;
  opacity: 1; /* required on iOS */
}
.input .active {
  color: #4d4d4d;
}

.input span {
  position: absolute;
  right: 12px;
  font-size: 14px;
  color: #737373;
}
button.fill,
button.outline {
  border-radius: 12px;
  height: 48px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}
button.fill {
  color: #ffffff;
  background-color: #b11e88;
  border: 1px solid #b11e88;
}
button.outline {
  color: #b11e88;
  background-color: #ffffff;
  border: 1px solid #e1c2eb;
}
button.light {
  font-weight: 500;
  font-size: 14px;
  height: 48px;
  border-radius: 8px;
  background-color: #fff7f5;
  color: #b11e88;
}

button.light-rest {
  font-weight: 500;
  font-size: 14px;
  height: 48px;
  border-radius: 8px;
  background-color: #faf5ff;
  color: #5a187a;
}

.hide_password,
.show_password {
  position: absolute;
  right: 0%;
  top: 20%;
  width: 24px;
  margin-right: 12px;
  cursor: pointer;
}
.show_password {
  background-image: url("./assets/common/show.svg");
}
.hide_password {
  background-image: url("./assets/common/hide.svg");
}

/* thereeeeeee is
      modaaaaaaaaaaaaal
        styleeeeeeeeeeeeeeeee
*/

.blur-bg {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 990;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  width: 100%;
  height: 100vh;
  overflow: hidden;
  /* background-color: rgba(156, 156, 156, 0.247); */
  -webkit-backdrop-filter: blur(4px) brightness(95%);
  backdrop-filter: blur(4px) brightness(95%);
  /* -webkit-overflow-scrolling: auto !important; */
  -webkit-overflow-scrolling: touch;
}
.modal-container {
  position: fixed;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 995;
  width: 100%;
  height: 100%;
}
.modal {
  position: absolute;
  bottom: -100%;
  transition: 0.5s ease;
  width: 100%;
  max-width: 768px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 991;
  display: none;
  flex-direction: column;
  padding: 12px 12px 110px;
}

.address {
  flex-direction: column;
  gap: 12px;
  padding: 16px 12px;
}
.address .head {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.address .description {
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #737373;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.edit {
  background-image: url("./assets/common/pen.svg");
  width: 24px;
}
.delete {
  background-image: url("./assets/common/trash.svg");
  width: 24px;
}

.status {
  border-radius: 8px;
  padding: 4px 8px;
}

.btn .open,
.restaurant .open {
  background-color: #e4fbe2;
  color: #48e639;
}

.btn .closed,
.restaurant .closed {
  background-color: #ffeeeb;
  color: #b11e88;
}
input::placeholder {
  color: #b3b3b3;
}

.search {
  width: 100%;
  position: relative;
  padding: 8px 8px 8px 40px;
}

.search .icon {
  position: absolute;
  left: 8px;
  width: 24px;
  background-image: url("./assets/customer/restaurant/search.svg");
}
.rate {
  display: flex;
  gap: 4px;
  align-items: flex-end;
}
.rate .star {
  width: 20px;
  height: 20px;
  background-image: url("./assets/common/star.svg");
}
.rate span {
  font-size: 12px;
  color: #b3b3b3;
}

.line {
  height: 48px;
  width: 1px;
  background-color: #f0edf2;
}

.tip {
  background-image: url("./assets/common/tip.svg");
  background-size: 24px;
  width: 24px;
  height: 24px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url("./assets/common/calendar.svg") no-repeat;
  width: 22px;
  height: 22px;
  background-size: 22px;
  border-width: thin;
}
.banner {
  width: 108px;
  height: 32px;
  background-image: url("./assets/common/nordantia.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.light-banner {
  width: 108px;
  height: 32px;
  background-image: url("./assets/common/light.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
/* customization vuetify switch */
div.v-application--wrap {
  flex: 1 1 auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  min-height: 0vh;
  max-width: 100%;
  position: relative;
}

.theme--light.v-application {
  background: transparent !important;
}

/* end vutify custom */

.photo_input {
  height: 92px;
  border-radius: 8px;
  padding-top: 12px;
  border: 1px dashed #776282;
  background-color: #f8f9fa;
  background-size: 100%;
  background-position: center;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.photo_input input[type="file"] {
  display: none;
}
.photo_input label#upload {
  background-size: 56px;
  background-image: url("./assets/restaurant/home/plus.svg");
}
.photo_input label {
  border-radius: 8px;
  width: 56px;
  background-position: center;
  min-height: 56px;
  background-size: 56px;
}

.photo_input span {
  font-size: 12px;
  padding-bottom: 12px;
  text-align: center;
  color: #776282;
}

input[type="checkbox"] {
  cursor: pointer;
  appearance: none;
  background-image: url("./assets/common/unchecked.svg");
  width: 24px;
  height: 24px;
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center;
}
input[type="checkbox"]:checked {
  background-image: url("./assets/common/checked.svg");
}
span.badge {
  color: #b11e88;
  position: absolute;
  top: -21px;
  right: 37%;
  font-size: 46px;
}

.loading {
  background-image: url("./assets/common/loading.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 128px;
  display: block;
  position: fixed;
  z-index: 999;
  background-color: #ffffffc0;
  backdrop-filter: blur(8px);
  height: 100%;
  width: 100%;
  right: 0;
  top: 0%;
}

/* scrollbar styles */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
/* Customer Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #faf5ff;
  border-radius: 6px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #f5f6f7;
  border-radius: 6px;
  transition: 0.4s ease;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f1e5fd;
  transition: 0.4s ease;
}

/* range slider custom styles */
.v-slider__thumb {
  width: 24px !important;
  height: 24px !important;
}
.v-slider__thumb:before {
  width: 22px !important;
  height: 22px !important;
  left: 1px !important;
  top: 1px !important;
}

.v-slider--horizontal .v-slider__track-container {
  height: 4px !important;
}

.theme--light.v-slider .v-slider__track-background {
  background: #ffeeeb !important;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.theme--light.v-slider .v-slider__track-fill {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.v-slider__thumb-label {
  font-size: 14px !important;
  background: transparent !important;
  color: #ffffff !important;
  height: 14px;
  transform: translateY(0%) translateY(15px) translateX(-32%) rotate(45deg) !important;
}
.slidecontainer .theme--light.v-messages {
  display: none !important;
}

.v-application .primary.lighten-3 {
  background-color: #ffeeeb !important;
  border-color: #ffeeeb !important;
}
</style>
