var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.customer_items.data),function(category,index){return (category.subcategories.length)?_c('div',{key:index,staticClass:"category"},[(category.subcategories.length)?_c('a',{staticClass:"title",attrs:{"id":category.id,"href":'#' + category.id}},[_vm._v(" "+_vm._s(category.title)+" ")]):_vm._e(),(category.products)?_c('div',{staticClass:"items"},_vm._l((category.products),function(item,jindex){return (item.status == 1)?_c('div',{key:jindex,staticClass:"item"},[_c('div',{staticClass:"btn",on:{"click":function($event){return _vm.addToCart(item)}}},[_c('div',{staticClass:"detail"},[_c('div',{staticClass:"head"},[_c('h3',[_vm._v(" "+_vm._s(item.title)+" ")])]),_c('div',{staticClass:"ingredients",domProps:{"innerHTML":_vm._s(
                _vm.$i18n.locale == 'en'
                  ? item.description_en
                  : item.description_fi
              )}}),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"price"},[(item.discount > 0)?_c('div',[_vm._v(" "+_vm._s(_vm.formatPrice( item.prices[0].price - (item.prices[0].price * item.discount) / 100 ))+"   "),_c('s',[_vm._v(_vm._s(_vm.formatPrice(item.prices[0].price)))])]):_c('div',[_vm._v(" "+_vm._s(_vm.formatPrice(item.prices[0].price))+" ")])])])]),(item.photo)?_c('div',{staticClass:"photo",style:([
              item.photo
                ? {
                    'background-image': 'url(' + item.photo + ')',
                  }
                : '' ]),on:{"click":function($event){return _vm.openItem(item)}}}):_vm._e()])]):_vm._e()}),0):_vm._l((category.subcategories),function(subcategory,jindex){return _c('div',{staticClass:"items"},[_c('a',{staticClass:"subcat",attrs:{"id":subcategory.id,"href":'#' + subcategory.id}},[_vm._v(" "+_vm._s(subcategory.title)+" ")]),_c('div',{staticClass:"items"},_vm._l((subcategory.products),function(item,jindex){return (item.status == 1)?_c('div',{key:jindex,staticClass:"item"},[_c('div',{staticClass:"btn",on:{"click":function($event){return _vm.addToCart(item)}}},[_c('div',{staticClass:"detail"},[_c('div',{staticClass:"head"},[_c('h3',[_vm._v(" "+_vm._s(item.title)+" ")])]),_c('div',{staticClass:"ingredients",domProps:{"innerHTML":_vm._s(
                  _vm.$i18n.locale == 'en'
                    ? item.description_en
                    : item.description_fi
                )}}),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"price"},[(item.discount > 0)?_c('div',[_vm._v(" "+_vm._s(_vm.formatPrice( item.prices[0].price - (item.prices[0].price * item.discount) / 100 ))+"   "),_c('s',[_vm._v(_vm._s(_vm.formatPrice(item.prices[0].price)))])]):_c('div',[_vm._v(" "+_vm._s(_vm.formatPrice(item.prices[0].price))+" ")])])])]),(item.photo)?_c('div',{staticClass:"photo",style:([
                item.photo
                  ? {
                      'background-image': 'url(' + item.photo + ')',
                    }
                  : '' ]),on:{"click":function($event){return _vm.openItem(item)}}}):_vm._e()])]):_vm._e()}),0)])})],2):_vm._e()}),0)}
var staticRenderFns = []

export { render, staticRenderFns }