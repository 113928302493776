<template>
  <div>
    <div
      v-for="(category, index) in customer_items.data"
      :key="index"
      class="category"
      v-if="category.subcategories.length"
    >
      <a
        :id="category.id"
        :href="'#' + category.id"
        v-if="category.subcategories.length"
        class="title"
      >
        {{ category.title }}
      </a>
      <div v-if="category.products" class="items">
        <div
          class="item"
          v-for="(item, jindex) in category.products"
          v-if="item.status == 1"
          :key="jindex"
        >
          <div @click="addToCart(item)" class="btn">
            <div class="detail">
              <div class="head">
                <!-- <div v-if="item.discount > 0" class="discount">
                  {{ "-" + item.discount + "%" }}
                </div> -->
                <h3>
                  {{ item.title }}
                </h3>
              </div>
              <div
                v-html="
                  $i18n.locale == 'en'
                    ? item.description_en
                    : item.description_fi
                "
                class="ingredients"
              ></div>
              <div class="footer">
                <div class="price">
                  <div v-if="item.discount > 0">
                    {{
                      formatPrice(
                        item.prices[0].price - (item.prices[0].price * item.discount) / 100
                      )
                    }}
                    &nbsp;
                    <s>{{ formatPrice(item.prices[0].price) }}</s>
                  </div>
                  <div v-else>
                    {{ formatPrice(item.prices[0].price) }}
                  </div>
                </div>
                <!-- <add-item v-if="make_order == 1" :item="item"></add-item> -->
              </div>
            </div>

            <div
              @click="openItem(item)"
              class="photo"
              v-if="item.photo"
              :style="[
                item.photo
                  ? {
                      'background-image': 'url(' + item.photo + ')',
                    }
                  : '',
              ]"
            ></div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="items"
        v-for="(subcategory, jindex) in category.subcategories"
      >
        <a :id="subcategory.id" :href="'#' + subcategory.id" class="subcat">
          {{ subcategory.title }}
        </a>
        <div class="items">
          <div
            class="item"
            v-for="(item, jindex) in subcategory.products"
            v-if="item.status == 1"
            :key="jindex"
          >
            <div @click="addToCart(item)" class="btn">
              <div class="detail">
                <div class="head">
                  <!-- <div v-if="item.discount > 0" class="discount">
                  {{ "-" + item.discount + "%" }}
                </div> -->
                  <h3>
                    {{ item.title }}
                  </h3>
                </div>
                <div
                  v-html="
                    $i18n.locale == 'en'
                      ? item.description_en
                      : item.description_fi
                  "
                  class="ingredients"
                ></div>
                <div class="footer">
                  <div class="price">
                    <div v-if="item.discount > 0">
                      {{
                        formatPrice(
                          item.prices[0].price - (item.prices[0].price * item.discount) / 100
                        )
                      }}
                      &nbsp;
                      <s>{{ formatPrice(item.prices[0].price) }}</s>
                    </div>
                    <div v-else>
                      {{ formatPrice(item.prices[0].price) }}
                    </div>
                  </div>
                  <!-- <add-item v-if="make_order == 1" :item="item"></add-item> -->
                </div>
              </div>

              <div
                @click="openItem(item)"
                class="photo"
                v-if="item.photo"
                :style="[
                  item.photo
                    ? {
                        'background-image': 'url(' + item.photo + ')',
                      }
                    : '',
                ]"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import AddItem from "./AddItem.vue";

export default {
  // components: { AddItem },
  methods: {
    addToCart(item) {
      this.selecting_item.data = item;

      if (this.$route.name == "Shop" && this.make_order == 1) {
        if (
          localStorage.menu &&
          localStorage.menu != localStorage.shop_id &&
          localStorage.cart &&
          JSON.parse(localStorage.cart).length > 0
        ) {
          this.showModal("clear_basket_modal");
        } else {
          localStorage.menu = localStorage.shop_id;
          localStorage.restaurant_name = this.$store.state.restaurant;

          this.addExtra(item);
        }
      }
      if (this.$route.name == "Basket") {
        this.addExtra(item);
      }
    },
    addExtra(item) {
      if (this.$route.name == "Shop") {
        this.$router
          .replace(`/shops/${this.$route.params.id}?item=${item.id}`)
          .catch(() => {});
      }

      if (this.$route.name == "Basket") {
        this.$router.replace(`/basket?item=${item.id}`).catch(() => {});
      }
      console.log(item);

      this.openItemModal();
    },
  },
  watch: {
    $route(to, from) {
      if (from.query.item) {
        this.closeModal("extras_modal");
      }
    },
  },
  data() {
    return {
      selected_item: {},
    };
  },
  props: {
    make_order: 0,
  },
};
</script>
<style scoped>
.item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  gap: 12px;
}

.items {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  gap: 12px;
}
.btn {
  justify-content: flex-start;
  gap: 12px;
  padding: 12px;
  background-color: transparent;
  border-bottom: 1px solid #f5f6f7;
  cursor: pointer;
}
.category {
  display: flex;
  flex-direction: column;
  padding: 0;
}
.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #b11e88;
  padding: 120px 12px 0;
  margin-top: -96px;
  position: static;
  z-index: -1;
}

.subcat{
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #b11e88;
  padding: 110px 12px 0;
  margin-top: -96px;
  position: static;
  z-index: -1;
}

s {
  font-weight: 400;
  font-size: 10px;
  color: #b3b3b3;
}

.discount {
  font-weight: 500;
  font-size: 12px;
  border-radius: 8px;
  background-color: #b11e88;
  color: #f5f6f7;
  padding: 4px 8px;
}

h3 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  color: #4d4d4d;
}

.detail {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 64%;
  width: 70%;
  height: 100%;
}
.detail span {
  font-weight: 300;
  font-size: 12px;
  color: #b3b3b3;
}
.head {
  display: flex;
  justify-content: flex-start;
  gap: 4px;
  align-items: center;
  width: 100%;
}
.type {
  color: #b3b3b3;
  font-size: 14px;
  width: 100%;
}
.ingredients {
  font-weight: 300;
  font-size: 14px;
  color: #b3b3b3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 16px;
}

.footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.price {
  font-weight: 400;
  font-size: 14px;
  color: #4d4d4d;
}
.photo {
  min-width: 86px;
  width: 30%;
  height: 82px;
  border-radius: 8px;
  background-image: url("../../assets/customer/menu/item.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media screen and (min-width: 786px) {
  .category {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
  }

  .item {
    width: 32%;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 0.125rem 0px,
      rgba(0, 0, 0, 0.12) 0px 0.125rem 0.125rem 0px;
    border-radius: 8px;
  }

  .items {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    gap: 12px;
    padding: 0 10px;
  }

  .btn {
    border-bottom: none;
  }

  .title {
    padding-bottom: 8px;
  }

  #menu_category {
    max-width: 100%;
  }
}
</style>
