var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{attrs:{"to":'/shops/' + _vm.restaurant.id}},[_c('div',{staticClass:"btn restaurant"},[(_vm.restaurant.status == 0)?_c('div',{staticClass:"closed"},[_vm._v(" "+_vm._s(_vm.$t("menu.closed"))+" ")]):_c('div'),_c('div',{staticClass:"photo",style:([
        _vm.restaurant.photo
          ? {
              'background-image': 'url(' + _vm.restaurant.photo + ')',
            }
          : '',
        _vm.restaurant.status == 1
          ? {
              opacity: '1',
            }
          : {
              opacity: '0.5',
            } ])},[_c('div',{staticClass:"head"},[(
            _vm.restaurant.delivery == 1 && _vm.restaurant.free_delivery_option == 1
          )?_c('div',{staticClass:"free-delivery"},[_c('div',{staticClass:"delivery-fee"},[_c('span',[_vm._v("Free")]),_c('div',{staticClass:"icon bike-white"}),_vm._v(" : ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t("menu.free_delivery_for.part_1") + _vm.formatPrice(_vm.restaurant.minimum_order_free_delivery))+" ")]),(_vm.restaurant.first_free_delivery_option == 1)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("menu.free_delivery_for.part_1") + _vm.formatPrice(_vm.restaurant.first_min_amount_free_delivery) + _vm.$t("menu.free_delivery_for.part_2") + _vm.restaurant.first_max_distance_free_delivery + " km")+" ")]):_vm._e()]):_vm._e(),(_vm.restaurant.delivery == 1)?_c('div',{staticClass:"more-detail"},[_c('div',{staticClass:"min-amount"},[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.restaurant.min_amount_order_delivery) + " " + _vm.$t("customer_home.minimum"))+" ")]),_c('span',{staticClass:"seprater"},[_vm._v("•")]),_c('div',{staticClass:"time-estimate"},[_vm._v(" "+_vm._s(_vm.restaurant.max_preparation_time ? _vm.restaurant.max_preparation_time : 0)+" min ")])]):_vm._e()])]),_c('div',{staticClass:"detail"},[_c('div',{staticClass:"head"},[_c('h3',[_vm._v(" "+_vm._s(_vm.restaurant.title)+" ")]),_c('div',{staticClass:"right-side"},[_c('div',{staticClass:"rate"},[_c('div',{staticClass:"icon star"}),_c('span',[_vm._v(" "+_vm._s(_vm.restaurant.average ? _vm.restaurant.average : 0 + " / 5")+" ")])])])]),_c('div',{staticClass:"footer"},[(_vm.restaurant.status == 1)?_c('div',{staticClass:"serves"},[(
              _vm.restaurant.cash_pickup == 1 || _vm.restaurant.online_pickup == 1
            )?_c('div',{staticClass:"serve"},[_c('div',{staticClass:"icon"}),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.$t("menu.pick_up")))])]):_vm._e(),(
              _vm.restaurant.cash_delivery == 1 || _vm.restaurant.online_delivery == 1
            )?_c('div',{staticClass:"serve"},[_c('div',{staticClass:"icon"}),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.$t("menu.delivery")))])]):_vm._e()]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }