<template>
  <div id="contact_us_modal" class="modal">
    <div class="question">
      <h3>{{ $t("registration.contact") }}</h3>
      <a v-if="$route.name != 'Account'" href="tel:0405072328">
        <button class="cancel">040 507 2328</button>
      </a>
    </div>
    <a href="mailto:service@nordantia.com">
      <button class="accept">service@nordantia.com</button>
    </a>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.modal {
  height: 340px;
  gap: 12px;
  justify-content: flex-start;
}
.question {
  background-color: #ffffff;
  border-radius: 16px;
}
h3 {
  height: 64px;
  padding: 16px;
  font-size: 18px;
  color: #4d4d4d;
  border-bottom: 1px solid #f5f6f7;
}
button {
  background-color: #ffffff;
  text-align: center;
  width: 100%;
  height: 48px;
  border-radius: 16px;
  color: #b11e88;
  font-size: 14px;
  line-height: 16px;
}
</style>