import Vue from "vue";
import store from "../store";
import myData from "../mainData";
import VueRouter from "vue-router";
import NotFound from "../views/NotFound.vue";

// customer views
import Home from "../views/Home.vue";
import History from "../views/History.vue";
import Account from "../views/Account.vue";
import Profile from "../views/account/Profile.vue";
import Favorites from "../views/account/Favorites.vue";
import Addresses from "../views/account/Addresses.vue";
import ChangePassword from "../views/account/ChangePassword.vue";
import Menu from "../views/Menu.vue";
import Basket from "../views/Basket.vue";
import Checkout from "../views/Checkout.vue";
import Order from "../views/Order.vue";
import Login from "../views/registration/Login.vue";
import Signup from "../views/registration/Signup.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/history",
    name: "History",
    component: History,
  },
  {
    path: "/account",
    name: "Account",
    component: Account,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/favorites",
    name: "Favorites",
    component: Favorites,
  },
  {
    path: "/addresses",
    name: "Addresses",
    component: Addresses,
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: ChangePassword,
  },
  {
    path: "/shops/:id",
    name: "Shop",
    component: Menu,
    props: true,
  },
  {
    path: "/basket",
    name: "Basket",
    component: Basket,
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: Checkout,
  },
  {
    path: "/order/:id",
    name: "Order",
    component: Order,
    props: true,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/sign-up",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/404",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Navigation Guard for redirect user when authenticated
router.beforeEach((to, from, next) => {
  if (["Main"].includes(to.name) && localStorage.token) next({ name: "Login" });
  else next();
});

// Navigation Guard for customer side
router.beforeEach((to, from, next) => {
  if (
    ["Addresses", "Favorites", "Profile"].includes(to.name) &&
    !localStorage.token
  ) {
    localStorage.redirect_to = to.name;

    next({ name: "Login" });
  } else next();
});

router.beforeEach((to, from, next) => {
  if (["Login", "Signup"].includes(to.name) && localStorage.token)
    next({ name: "Home" });
  else next();
});

export default router;
