<template>
  <div class="categories" @mouseleave="hideSubCategories()">
    <a
      @click="selectCategory(-1, null)"
      :class="[active == -1 ? 'active' : 'category']"
      href="#-1"
      id="category-1"
    >
      {{ $t("menu.all") }}
    </a>
    <a
      :class="[active == category.id ? 'active' : 'category']"
      v-for="(category, index) in customer_categories.data"
      :id="'category' + category.id"
      :key="index"
      :href="'#' + category.id"
      @mouseover="showSubCategories(category)"
      v-if="category.subcategories.length"
    >
      {{ category.title }}
    </a>
    <div class="sub-cats" v-if="subcategories.length">
      <a
        :class="[active == subcategory.id ? 'active' : 'subcategory']"
        v-for="(subcategory, index) in subcategories"
        :key="index"
        :id="'category' + subcategory.id"
        :href="'#' + subcategory.id"
      >
        {{ subcategory.title }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    const vm = this;
    if (vm.$route.hash) {
      vm.selectCategory(vm.$route.hash.slice(1), null);
      if (vm.$route.hash.slice(1) >= 0) {
        setTimeout(() => {
          var element = document.getElementById(vm.$route.hash.slice(1));
          element.scrollIntoView();
        }, 500);
      }
    } else {
      this.selectCategory(-1, null);
    }
  },
  computed: {
    hasPopular() {
      return (
        this.popular_items.data.items &&
        this.popular_items.data.items.length != 0
      );
    },
  },
  watch: {
    $route(to, from) {
      if (to.hash) {
        this.active = to.hash ? to.hash.slice(1) : -1;
        this.selectCategory(this.active, null);
      }
    },
  },
  methods: {
    showSubCategories(category) {
      this.subcategories = category.subcategories;
      console.log(category.subcategories, "helliiiookdoflkdnf");
    },
    hideSubCategories() {
      this.subcategories = [];
    },
    selectCategory(category_id, index) {
      this.$router.replace({ hash: category_id }).catch(() => {});

      this.active = category_id;

      if (this.active == -1) {
        this.gotoTop();
      }
    },
    gotoTop() {
      let view = document.getElementById("menu_content");

      view.scrollTop = 0;
    },
  },
  props: {
    categories: [],
    all_items: [],
    popular_items: [],
  },
  data() {
    return {
      active: 0,
      subcategories: [],
    };
  },
};
</script>
<style scoped>
.categories {
  display: -webkit-box;
  display: flex;
  overflow-x: auto;
  padding: 0px 16px;
  min-height: 85px;
  height: 50px;
  max-width: 1540px;
  margin: 0px auto;
  width: 100%;
  position: relative;
  /* scroll-behavior: smooth; */
}

.categories a {
  cursor: pointer;
  width: fit-content;
  min-width: fit-content;
  margin: 0px 5px 0px 0px;
  padding: 8px 12px;
  font-size: 16px;
  height: 38px;
  text-align: center;
}

.category {
  color: #b3b3b3;
}
.active {
  background-color: #fff7f5;
  color: #b11e88;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  border-radius: 8px;
}
.sub-cats {
  position: absolute;
  background-color: #fff;
  bottom: 0px;
  z-index: 999;
  height: 28px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.subcategory {
  cursor: pointer;
  width: fit-content;
  min-width: fit-content;
  margin: 0px 5px 0px 0px;
  padding: 8px 12px;
  font-size: 16px;
  color: #b3b3b3;
  text-align: center;
}
</style>
