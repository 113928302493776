<template>
  <div class="opening">
    <div class="days">
      <div class="day" v-for="day in days" :key="day.id">
        <div v-if="day.day == 'monday'">
          {{ $t("restaurant.mon") }}
        </div>
        <div v-if="day.day == 'tuesday'">
          {{ $t("restaurant.tue") }}
        </div>
        <div v-if="day.day == 'wednesday'">
          {{ $t("restaurant.wed") }}
        </div>
        <div v-if="day.day == 'thursday'">
          {{ $t("restaurant.thu") }}
        </div>
        <div v-if="day.day == 'friday'">
          {{ $t("restaurant.fri") }}
        </div>
        <div v-if="day.day == 'saturday'">
          {{ $t("restaurant.sat") }}
        </div>
        <div v-if="day.day == 'sunday'">
          {{ $t("restaurant.sun") }}
        </div>
      </div>
    </div>
    <div class="times">
      <div class="time" v-for="time in days" :key="time.id">
        <div class="icon clock"></div>

        <div class="hours">
          {{
            time.status == 1 ? time.start_time + " - " + time.end_time : $t("menu.closed")
          }}
        </div>
      </div>
    </div>

    <div class="times">
      <div
        class="time"
        v-for="delivery_time in delivery_times"
        :key="delivery_time.id"
      >
        <div class="icon bike"></div>
        <div class="hours">
          {{
            delivery_time.status == 1
              ? delivery_time.start_time + " - " + delivery_time.end_time
              : $t("menu.closed")
          }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    days: [],
    delivery_times: [],
  },
};
</script>
<style scoped>
.opening {
  display: flex;
  width: 100%;
  justify-content: space-between;
  line-height: 19px;
  margin-top: 10px;
  font-size: 14px;
}

.opening .days .day {
  color: #776282;
}
.opening .times .time {
  color: #b3b3b3;
  display: flex;
  align-items: center;
  gap: 8px;
}
.bike,
.clock {
  background-color: transparent;
  width: 15px;
  height: 15px;
  background-size: 15px;
}
.clock {
  background-image: url("../../assets/customer/menu/clock.svg");
}
.bike {
  background-image: url("../../assets/customer/menu/bike.svg");
}
</style>
