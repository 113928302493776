<template>
  <div class="menu">
    <div ref="headRef" id="menu_header" class="header">
      <div class="container">
        <div class="title-container">
          <!-- <back-button class="icon back-btn" :back_to="'/'"></back-button> -->

          <div id="menu_title" class="title">{{ title }}</div>
        </div>

        <div class="actions">
          <button @click="openResturant" class="icon info"></button>
        </div>
      </div>
      <!-- categories -->
      <categories
        id="menu_category"
        :all_items="all_items"
        :popular_items="popular_items"
        :categories="categories"
      ></categories>
      <!-- end of categories -->
    </div>
    <div id="menu_content" class="content">
      <!-- banner -->
      <menu-banner
        :restaurant="restaurant"
        :rate="rate"
        :status="status"
        :reviews="reviews"
        :photo="restaurant_photo[0]"
      ></menu-banner>

      <!-- end of banner -->

      <!-- Type and reviews -->
      <div class="detail">
        <div class="row">
          <div class="col">
            <div class="title">{{ title }}</div>
            <div class="type">{{ restaurant.type }}</div>
          </div>
        </div>

        <div class="row">
          <div v-if="restaurant.banner_text" class="banner_text">
            {{ restaurant.banner_text }}
          </div>
          <div class="rate">
            <div class="icon star"></div>
            <span>
              {{ rate + "/5" }}
            </span>
            <span class="review">({{ reviews }})</span>
          </div>
        </div>

        <!-- End of Type and reviews -->
        <div class="big_row">
          <!-- Working hours -->
          <div class="row">
            <div class="working-hours">
              <div class="icon clock"></div>
              <div class="hours">{{ today_start }} - {{ today_end }}</div>
            </div>

            <div class="working-hours">
              {{ $t("menu.working_hours") }}
            </div>
          </div>
          <!-- End of Working hours -->

          <!-- Services -->
          <div class="row">
            <div class="head">{{ $t("menu.availabe_for") }}</div>
            <div class="serves">
              <div
                :class="[
                  restaurant.cash_pickup == 0 && restaurant.online_pickup == 0
                    ? 'off-serve'
                    : '',
                  'serve',
                ]"
              >
                <div class="icon"></div>
                <div class="desc">{{ $t("menu.pick_up") }}</div>
              </div>
              <div
                :class="[
                  restaurant.cash_delivery == 0 &&
                  restaurant.online_delivery == 0
                    ? 'off-serve'
                    : '',
                  'serve',
                ]"
              >
                <div class="icon"></div>
                <div class="desc">{{ $t("menu.delivery") }}</div>
              </div>
            </div>
          </div>

          <!--End of Services -->
        </div>
      </div>

      <!-- End of Actions -->

      <!-- categories -->
      <categories
        :all_items="all_items"
        :popular_items="popular_items"
        :categories="categories"
      ></categories>
      <!-- end of categories -->

      <!-- <h2>All items</h2> -->

      <!-- items -->
      <items
        id="menu_items"
        :make_order="make_order"
        @selected_item="selectedItem"
      ></items>
      <!-- end of items -->
    </div>
    <div id="blur_background" class="blur-bg">
      <succeed-modal :message="global_success.message"></succeed-modal>
      <restaurant-info-modal :restaurant="restaurant"></restaurant-info-modal>
      <extras-modal
        :index="$store.state.cart.length - 1"
        :item="selecting_item.data"
      ></extras-modal>
      <clear-basket-modal></clear-basket-modal>
    </div>
    <continue v-if="make_order == 1"></continue>
  </div>
</template>
<script>
import BackButton from "../components/common/BackButton.vue";
import Categories from "../components/customer/Categories.vue";
import Continue from "../components/customer/Continue.vue";
import Items from "../components/customer/Items.vue";
import MenuBanner from "../components/customer/MenuBanner.vue";
import RestaurantInfoModal from "../components/customer/RestaurantInfoModal.vue";
import SucceedModal from "../components/customer/SucceedModal.vue";
import ExtrasModal from "../components/customer/ExtrasModal.vue";
import ClearBasketModal from "../components/customer/ClearBasketModal.vue";

export default {
  mounted() {
    this.fetch();

    document.getElementById("menu_content").onscroll = function () {
      // fixing header
      let menu_header = document.getElementById("menu_header");
      let menu_title = document.getElementById("menu_title");
      let menu_category = document.getElementById("menu_category");

      if (
        document.getElementById("menu_content").scrollTop > 450 ||
        document.documentElement.scrollTop > 450
      ) {
        menu_header.style.background = "white";
        menu_title.style.display = "initial";
        menu_category.style.display = "flex";
      } else {
        menu_header.style.background = "transparent";
        menu_title.style.display = "none";
        menu_category.style.display = "none";
      }
      // end of fixing header
    };
  },
  methods: {
    selectedItem(val) {
      this.selected_item = val;
    },
    fetch() {
      this.global_loading.show = true;
      this.$http
        .get(this.$hostname + "/shops/" + this.id, this.token.config)
        .then((resp) => {
          this.successRequest();
          localStorage.shop_id = this.id;

          this.customer_categories.data = resp.data.categories.filter((n) => n);

          this.customer_items.data = resp.data.categories.filter((n) => n);
          this.setRestaurantInfo(resp.data.shop)
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    setRestaurantInfo(restaurant) {
      this.restaurant = restaurant;

      this.make_order = restaurant.status;

      localStorage.delivery_charge = restaurant.delivery_charge;

      localStorage.minimum_order_free_delivery =
        restaurant.free_delivery_option == 1
          ? restaurant.minimum_order_free_delivery
          : 0;

      localStorage.pick_up = restaurant.pick_up;
      localStorage.delivery = restaurant.delivery;
      localStorage.make_order = restaurant.make_order;
    },
    openResturant() {
      this.showModal("restaurant_info");

      this.$router
        .push({
          query: { shop: true },
        })
        .catch(() => {});
    },
    setTodayWorkingHours(opening_times) {
      let today = new Date();

      this.today_start =
        today.getDay() == 0
          ? opening_times[opening_times.length - 1].from
          : opening_times[today.getDay() - 1].from;

      this.today_end =
        today.getDay() == 0
          ? opening_times[opening_times.length - 1].to
          : opening_times[today.getDay() - 1].to;
    },
  },
  watch: {
    $route(to, from) {
      if (from.query.restaurant) {
        this.closeModal("restaurant_info");
      }
    },
  },
  props: {
    id: 0,
  },
  components: {
    BackButton,
    MenuBanner,
    Categories,
    Items,
    RestaurantInfoModal,
    Continue,
    SucceedModal,
    ExtrasModal,
    ClearBasketModal,
  },
  data() {
    return {
      title: "",
      header_name: "",
      rate: 0,
      reviews: 0,
      status: 1,
      selected_item: {},
      categories: [],
      restaurant: {},
      main_photo: "",
      restaurant_id: 0,
      make_order: 1,
      restaurant_photo: [],
      all_items: [],
      today_start: "",
      today_end: "",
      delivery_start: "",
      delivery_end: "",
      night_shift_charge: 0,
    };
  },
};
</script>
<style scoped>
.menu {
  overflow: hidden;
  max-height: 100vh;
}
#menu_header {
  padding: 0;
}
#menu_title {
  display: none;
}
#menu_category {
  display: none;
  max-width: 1540px;
  margin: auto;
}
#menu_items {
  max-width: 1540px;
  margin: 0px auto;
  width: 100%;
}
.title-container {
  display: flex;
  align-items: center;
  gap: 8px;
}
.header {
  background: transparent;
}
.container {
  justify-content: space-between;
  border: none;
  padding: 16px 16px 0;
}

.title {
  font-weight: 600;
  font-size: 24px;
  color: #4d4d4d;
}

.content {
  padding: 0 0 128px;
  gap: 12px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.actions {
  width: 84px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  gap: 4px;
}

.icon {
  width: 40px;
  height: 40px;
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #f5f6f7;
  border-radius: 8px;
}

.back-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content .btn {
  height: 40px;
  padding: 10px 0px;
  width: 100%;
}
.location .icon {
  background-image: url("../assets/customer/menu/location.svg");
}
.location {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 87%;
}
.location .description {
  font-weight: 300;
  font-size: 14px;
  color: #4d4d4d;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 85%;
}
.info {
  background-image: url("../assets/customer/menu/info.svg");
}

.reservation .icon {
  background-color: transparent;
  padding: 0px;
}
.reservation .caption {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 300;
  font-size: 14px;
  color: #4d4d4d;
}

.reservation .caption .icon {
  background-image: url("../assets/customer/navbar/reservation.svg");
}

.reservation .arrow {
  background-image: url("../assets/customer/menu/right.svg");
  height: 24px;
  background-position-y: center;
}
h2 {
  font-weight: 500;
  font-size: 22px;
  color: #737373;
}

.detail {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 16px;
  max-width: 1540px;
  width: 100%;
  margin: 0 auto;
}

.banner_text {
  font-weight: 400;
  font-size: 16px;
  color: #4d4d4d;
}
.row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.big_row {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.type {
  font-weight: 400;
  font-size: 14px;
  color: #b3b3b3;
}
.rate {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.rate span {
  color: #737373;
}

.review {
  font-size: 10px;
  color: #b3b3b3 !important;
}

.star {
  width: 20px;
  height: 20px;
  background-color: transparent;
}

.row .head {
  font-weight: 400;
  font-size: 14px;
  color: #737373;
}

.serves {
  display: flex;
  gap: 16px;
}
.serve {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #4d4d4d;
}

.serve .desc {
  font-weight: 400;
  font-size: 14px;
}
.serve .icon {
  background-image: url("../assets/customer/menu/check.svg");
  background-color: transparent;
  width: 12px;
  height: 12px;
  background-size: 12px;
}

.off-serve {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  color: #b3b3b3;
}

.delivery-hours .bike {
  background-color: transparent;
  width: 20px;
  height: 20px;
  background-size: 20px;
  background-image: url("../assets/customer/menu/bike.svg");
}

.night-shift .night {
  background-color: transparent;
  width: 20px;
  height: 20px;
  background-size: 20px;
  background-image: url("../assets/customer/menu/night.svg");
}

.working-hours,
.delivery-hours,
.night-shift {
  display: flex;
  align-items: center;
  gap: 8px;
}
.saved {
  border: 1px solid #ffeeeb;
  background-color: transparent;
}
.working-hours,
.delivery-hours,
.delivery-fee,
.night-shift,
.night-shift-price .desc,
.night-shift-price {
  font-weight: 400;
  font-size: 14px;
  color: #737373;
}
.night-shift-price {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  gap: 4px;
}
.clock {
  width: 18px;
  height: 18px;
  background-size: 18px;
  background-color: transparent;
  background-image: url("../assets/customer/menu/clock.svg");
}

.light {
  width: 100%;
  border: 1px solid;
}

@media screen and (min-width: 786px) {
  .content {
    max-width: 100%;
  }

  .container {
    max-width: 1540px;
  }
}
</style>
