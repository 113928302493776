<template>
  <div class="control">
    <button
      @click="removeFromCart"
      v-if="fetchQuantity > 0"
      :class="['icon', fetchQuantity == 1 ? 'trash' : 'minus']"
    ></button>

    <span v-if="fetchQuantity > 0">{{
      $route.name == "Basket" ? 1 : fetchQuantity
    }}</span>
    <button @click="addToCart" class="icon plus"></button>
  </div>
</template>

<script>
export default {
  props: {
    item: {},
    index_of_item: null,
  },
  computed: {
    fetchQuantity() {
      let quantity = 0;

      if (this.$store.state.cart) {
        this.$store.state.cart.forEach((element) => {
          if (element.item && element.item.id == this.item.id) {
            quantity += 1;
          }
        });
      }
      return quantity;
    },
  },
  watch: {
    $route(to, from) {
      if (from.query.item) {
        this.closeModal("extras_modal");
      }
    },
  },
  methods: {
    addToCart() {
      this.selecting_item.data = this.item;
      this.addExtra();
    },
    addExtra() {
      if (this.item.prices.length) {
        if (this.$route.name == "Basket") {
          this.$router.replace(`/basket?item=${this.item.id}`).catch(() => {});
        }

        this.showModal("extras_modal");
      } else {

        this.$store.dispatch("addItemToCart", {
          item: this.item,
          quantity: 1,
        });
      }
    },
    removeFromCart() {
      this.$store.dispatch("removeItemFromCart", {
        item: this.item,
        quantity: 1,
        index: this.index_of_item,
      });
    },
  },
};
</script>

<style scoped>
.control {
  display: flex;
  min-width: 100px;
  width: fit-content;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
  font-size: 14px;
  color: #776282;
}
.icon {
  width: 24px;
  height: 24px;
  background-size: 20px;
  background-color: #ffeeeb;
  border-radius: 8px;
}

.plus {
  background-image: url("../../assets/customer/menu/plus.svg");
}

.minus {
  background-image: url("../../assets/customer/menu/minus.svg");
}

.trash {
  background-image: url("../../assets/customer/menu/trash.svg");
}
</style>
