<template>
  <router-link :to="'/shops/' + restaurant.id">
    <div class="btn restaurant">
      <div v-if="restaurant.status == 0" class="closed">
        {{ $t("menu.closed") }}
      </div>
      <div v-else></div>
      <div
        class="photo"
        :style="[
          restaurant.photo
            ? {
                'background-image': 'url(' + restaurant.photo + ')',
              }
            : '',
          restaurant.status == 1
            ? {
                opacity: '1',
              }
            : {
                opacity: '0.5',
              },
        ]"
      >
        <div class="head">
          <div
            v-if="
              restaurant.delivery == 1 && restaurant.free_delivery_option == 1
            "
            class="free-delivery"
          >
            <div class="delivery-fee">
              <span>Free</span>
              <div class="icon bike-white"></div>
              :
            </div>
            <span>
              {{
                $t("menu.free_delivery_for.part_1") +
                formatPrice(restaurant.minimum_order_free_delivery)
              }}
            </span>
            <span v-if="restaurant.first_free_delivery_option == 1">
              {{
                $t("menu.free_delivery_for.part_1") +
                formatPrice(restaurant.first_min_amount_free_delivery) +
                $t("menu.free_delivery_for.part_2") +
                restaurant.first_max_distance_free_delivery +
                " km"
              }}
            </span>
          </div>
          <div v-if="restaurant.delivery == 1" class="more-detail">
            <div class="min-amount">
              {{
                formatPrice(restaurant.min_amount_order_delivery) +
                " " +
                $t("customer_home.minimum")
              }}
            </div>
            <span class="seprater">•</span>

            <div class="time-estimate">
              {{
                restaurant.max_preparation_time
                  ? restaurant.max_preparation_time
                  : 0
              }}
              min
            </div>
            <!-- <span class="seprater">•</span> -->

            <!-- <div
              v-if="restaurant.delivery_fees.length > 0"
              class="delivery-fee"
            >
              <div class="icon bike"></div>
              {{ deliveryFeeFormatter(restaurant) }}
            </div> -->
            <!-- <div v-else class="note">
              {{ $t("information.free_delivery") }}
            </div> -->
          </div>
        </div>
      </div>
      <div class="detail">
        <div class="head">
          <h3>
            {{ restaurant.title }}
          </h3>
          <div class="right-side">
            <div class="rate">
              <div class="icon star"></div>
              <span>
                {{ restaurant.average ? restaurant.average : 0 + " / 5" }}
              </span>
            </div>
          </div>
        </div>
        <div class="footer">
          <div v-if="restaurant.status == 1" class="serves">
            <div
              v-if="
                restaurant.cash_pickup == 1 || restaurant.online_pickup == 1
              "
              class="serve"
            >
              <div class="icon"></div>
              <div class="desc">{{ $t("menu.pick_up") }}</div>
            </div>
            <div
              v-if="
                restaurant.cash_delivery == 1 || restaurant.online_delivery == 1
              "
              class="serve"
            >
              <div class="icon"></div>
              <div class="desc">{{ $t("menu.delivery") }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>
<script>
export default {
  props: {
    restaurant: {},
  },
};
</script>
<style scoped>
.restaurant {
  justify-content: flex-start;
  /* gap: 12px; */
  flex-direction: column;
  border-bottom: 1px solid #f5f6f7;
  background-color: #f9e4f3;
  border-radius: 0;
  padding: 0;
  padding-bottom: 12px;
  position: relative;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
h3 {
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  font-weight: 500;
  color: #4d4d4d;
}
.detail {
  display: flex;
  flex-direction: column;
  gap: 8px;
  /* min-width: 64%; */
  width: 100%;
}

.head {
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
}

.more-detail {
  color: #4d4d4d;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #ffffff;
  box-shadow: -2px -2px 8px rgb(0 0 0 / 8%);
  border-radius: 4px;
  padding: 4px 8px;
  font-weight: 400;
  font-size: 12px;
}

.type {
  color: #b3b3b3;
  font-size: 14px;
  width: 30%;
}
.footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.delivery {
  display: flex;
  align-items: center;
  gap: 4px;
}
.delivery span {
  font-weight: 400;
  font-size: 12px;
  color: #b3b3b3;
}
.photo {
  width: 100%;
  height: 160px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-image: url("../../assets/customer/restaurant/restaurant.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
  padding: 8px;
  flex-direction: column;
  justify-content: flex-end;
}

.free-delivery {
  box-shadow: -2px -2px 8px rgb(0 0 0 / 8%);
  border-radius: 4px;
  padding: 4px 8px;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  background-color: #b11e88;
  display: flex;
  flex-direction: column;
}

.closed {
  background-color: #b11e88;
  color: #ffffff;
  border-radius: 4px;
  width: 59px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  left: 8px;
  top: 8px;
  position: absolute;
  z-index: 150;
}
.right-side {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 8px;
}

.no-delivery {
  font-weight: 400;
  font-size: 14px;
  color: #b3b3b3;
}

.serves {
  display: flex;
  gap: 16px;
}
.serve {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #4d4d4d;
}

.serve .desc {
  font-weight: 400;
  font-size: 14px;
}
.serve .icon {
  background-image: url("../../assets/customer/menu/check.svg");
  background-color: transparent;
  width: 12px;
  height: 12px;
  background-size: 12px;
}

.seprater {
  color: #b3b3b3;
  font-weight: 900;
}

.detail {
  padding: 8px;
}
.detail .head {
  flex-direction: row;
  justify-content: space-between;
}

.note {
  color: #b11e88;
}
.delivery-fee {
  display: flex;
  gap: 4px;
}
.bike {
  background-image: url("../../assets/customer/menu/bike.svg");
  background-color: transparent;
  width: 15px;
  height: 15px;
  background-size: 15px;
}

.bike-white {
  background-image: url("../../assets/customer/menu/bike_white.svg");
  background-color: transparent;
  width: 15px;
  height: 15px;
  background-size: 15px;
}
</style>
