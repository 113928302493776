var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"container"},[_c('back-button',{attrs:{"back_to":'/history'}}),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.order.status == "done" ? _vm.$t("order.order_details") : _vm.$t("order.order_status"))+" ")]),_c('span')],1)]),_c('div',{staticClass:"content"},[_c('order-card',{attrs:{"order":_vm.order}}),(_vm.order.status !== 'done' || _vm.order.payment_status == 0)?_c('div',{staticClass:"btn status"},[_c('div',{staticClass:"progress"},[_c('div',{class:[
            _vm.order.progress_status == 'coming' ? 'complete_bar' : '',
            'bar' ]}),_c('div',{staticClass:"step"},[_c('div',{class:[
              ['accepted', 'coming'].includes(_vm.order.progress_status)
                ? 'on'
                : 'off',
              'circle' ]},[_vm._v(" 1 ")])]),_c('div',{staticClass:"step"},[_c('div',{class:[
              ['coming'].includes(_vm.order.progress_status) ? 'on' : 'off',
              'circle' ]},[_vm._v(" 2 ")])])]),_c('div',{staticClass:"progress"},[_c('div',{class:[
            ['accepted', 'coming'].includes(_vm.order.progress_status)
              ? 'caption-on'
              : '',
            'caption' ]},[_vm._v(" "+_vm._s(_vm.$t("cart.accepted"))+" ")]),_c('div',{class:[
            ['coming'].includes(_vm.order.progress_status) ? 'caption-on' : '',
            'caption' ]},[_vm._v(" "+_vm._s(_vm.$t("cart.coming"))+" ")])]),(_vm.order.order_type == 'delivery')?_c('div',{staticClass:"delivery"},[_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.$t("order.delivery_time")))]),(_vm.time_over)?_c('counter',{attrs:{"end":_vm.order.delivered_at}}):_vm._e()],1):_vm._e(),(_vm.order.order_type == 'delivery')?_c('div',{staticClass:"delivery"},[_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.$t("order.delivery_fee")))]),_c('div',{staticClass:"method"},[_vm._v(_vm._s(_vm.formatPrice(_vm.order.delivery_charge)))])]):_vm._e(),_c('div',{staticClass:"payment"},[_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.$t("order.payment_method")))]),_c('div',{staticClass:"method"},[_vm._v(" "+_vm._s(_vm.order.payment_method == "online" ? _vm.$t("cart.online_payment") : _vm.order.payment_method == "cash" ? _vm.$t("cart.cash_card") : "Not defined")+" "),_c('span',{class:[_vm.order.payment_status == 1 ? 'paid' : 'unpaid']},[_vm._v(" "+_vm._s(_vm.order.payment_status == 1 ? "(" + _vm.$t("cart.paid") + ")" : "")+" ")])])])]):_vm._e(),_c('div',{staticClass:"btn items"},_vm._l((_vm.products),function(dish,index){return _c('div',{key:index,staticClass:"dish"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"photo",style:([
              dish.item.photo
                ? {
                    'background-image': 'url(' + dish.item.photo + ')',
                  }
                : '' ])}),_c('div',{staticClass:"detail"},[_c('div',{staticClass:"head"},[_c('h3',[_vm._v(" "+_vm._s(dish.item.title)+" ")])]),_c('div',{staticClass:"footer"})])]),_vm._l((dish.prices),function(extra){return _c('div',{key:extra.id,staticClass:"extra"},[_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s((extra.title ? extra.title : "") + "(" + extra.description + ")")+" ")]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"quantity"},[_vm._v("x1")]),(dish.item.discount > 0)?_c('div',{staticClass:"price"},[_c('s',[_vm._v(_vm._s(_vm.formatPrice(extra.price)))]),_vm._v("   "+_vm._s(_vm.formatPrice( extra.price - (extra.price * dish.item.discount) / 100 ))+" ")]):_c('div',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm.formatPrice(extra.price))+" ")])])])}),_vm._l((dish.cards),function(drink){return _c('div',{key:drink.id,staticClass:"extra"},[_c('div',{staticClass:"caption"},[_vm._v(_vm._s(drink.title))]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"quantity"},[_vm._v("x1")]),_c('div',{staticClass:"price"},[_vm._v(_vm._s(_vm.formatPrice(drink.price)))])])])})],2)}),0)],1),_c('div',{staticClass:"blur-bg",attrs:{"id":"blur_background"}},[_c('rate-modal'),_c('succeed-modal',{attrs:{"message":_vm.global_success.message}}),_c('payment-terms-modal')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }