<template>
  <div @click="next" :class="[continue_ordering ? 'checkout' : 'checkout_not']">
    <div
      :style="[
        completed_order || $route.name != 'Checkout'
          ? { opacity: 1 }
          : { opacity: 0.5 },
      ]"
      class="content"
    >
      <div class="cart">
        {{
          $route.name == "Checkout"
            ? $t("cart.submit_pay")
            : $route.name == "Basket" && can_not_order
            ? $t("call.call")
            : $t("menu.checkout")
        }}
      </div>
      <div class="price">
        <span>
          {{ total }}
        </span>
        <div class="icon"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Continue",
  mounted() {
    this.canGo();

    this.can_not_order =
      localStorage.pick_up == 0 &&
      localStorage.delivery == 0 &&
      localStorage.make_order == 0;

    if (
      this.$route.query.payment_status &&
      this.$route.query.payment_status == 1
    ) {
      if (this.$route.query.transaction_number) {
        localStorage.transaction_number = this.$route.query.transaction_number;
        this.submit();
      }
    }

    if (this.$route.query.payment_error_en) {
      this.global_error.show = true;

      this.global_error.message =
        localStorage.language == "en"
          ? this.$route.query.payment_error_en
          : this.$route.query.payment_error_fi;
    }
  },
  methods: {
    next() {
      if (this.$route.name == "Shop") {
        this.$router.push("/basket");
        return;
      }
      if (this.$route.name == "Basket") {
        if (
          localStorage.pick_up == 0 &&
          localStorage.delivery == 0 &&
          localStorage.make_order == 0
        ) {
          this.showModal("call_modal");
        } else {
          this.$router.push("/checkout");
        }
        return;
      }
      if (this.$route.name == "Checkout") {
        if (localStorage.payment_type == 2) {
          this.redirectToOnlinePaymnet();
        } else {
          if (this.completed_order && JSON.parse(localStorage.cart).length) {
            this.submit();
          }
        }
        return;
      }
    },
    submit() {
      let data = {
        shop_id: (localStorage.shop_id),
        payment_method: parseInt(localStorage.payment_type),
        order_type: parseInt(localStorage.order_type),
        comment: localStorage.comment,
        address_id: parseInt(localStorage.address_id),
        products: JSON.parse(localStorage.cart),
        total: this.totalInBasket(),
        lang: localStorage.language,
        preferred_receive_at: localStorage.preferred_receive_at,
        transaction_number: localStorage.transaction_number
          ? localStorage.transaction_number
          : "",
      };

      this.global_loading.show = true;
      this.$http
        .post(
          this.$hostname + `/client/shops/${localStorage.shop_id}/orders`,
          data,
          this.token.config
        )
        .then((resp) => {
          this.successRequest();

          this.done();

          setTimeout(() => {
            this.$router.push("/order/" + resp.data.order);
          }, 3000);
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    setOrderInLocalStorage(order) {
      if (localStorage.orders) {
        this.orders.push(localStorage.orders);
      }
      this.orders.push(parseInt(order));

      localStorage.setItem("orders", this.orders);
    },
    redirectToOnlinePaymnet() {
      if (this.completed_order && JSON.parse(localStorage.cart).length) {
        let data = {
          shop_id: localStorage.shop_id,
          order_type: localStorage.order_type,
          products: JSON.parse(localStorage.cart),
          total: this.totalInBasket(),
          lang: localStorage.language,
          email: localStorage.local_email,
          address_id: localStorage.address_id,
          promotion_code: localStorage.token ? localStorage.discount : "",
          order_request: {
            shop_id: localStorage.shop_id,
            order_type: localStorage.order_type,
            products: JSON.parse(localStorage.cart),
            total: this.totalInBasket(),
            lang: localStorage.language,
            email: localStorage.local_email,
            payment_method: localStorage.payment_type,
            take_away: localStorage.table ? 0 : 1,
            table_number: localStorage.table ? localStorage.table : 0,
            comment: localStorage.comment,
            address_id: localStorage.address_id,
            user_id: localStorage.local_user_id,
            promotion_code: localStorage.token ? localStorage.discount : "",
            preferred_receive_at: localStorage.preferred_receive_at,
          },
        };

        this.global_loading.show = true;

        this.$http
          .post(this.$hostname + "/pay-shop-order", data, this.token.config)
          .then((resp) => {
            this.successRequest();

            if (window.self.location.href == window.top.location.href) {
              window.location.href = resp.data.redirect_to;
            } else {
              window.open(resp.data.redirect_to, "_blank");
            }
          })
          .catch((err) => {
            // this.showErrors(err);
          });
      }
    },
    done() {
      this.clearStorage();

      this.$store.dispatch("clearCart");

      this.global_success.message = this.$t("cart.success_order");

      this.showModal("succeed_modal");
    },
    canGo() {
      if (this.totalInBasket() > 0) {
        if (
          this.$store.state.shop_id == localStorage.shop_id ||
          this.$route.name != "Shop"
        ) {
          this.checkout = true;
        } else {
          this.checkout = false;
        }
      }
    },
  },
  computed: {
    total() {
      return this.$store.state.total;
    },
    continue_ordering() {
      return this.totalInBasket() > 0;
    },
  },
  updated() {
    this.canGo();
  },
  props: {
    completed_order: false,
  },
  data() {
    return {
      orders: [],
      can_not_order: false,
      checkout: false,
    };
  },
};
</script>
<style scoped>
.checkout {
  display: flex;
  align-items: flex-end;
  position: fixed;
  width: 100%;
  bottom: 66px;
  z-index: 980;
  transition: 0.2s ease;
  padding: 0 16px;
  background: #ffffff;
  height: 55px;
  cursor: pointer;
}
.checkout_not {
  display: flex;
  align-items: flex-end;
  width: 100%;
  position: fixed;
  bottom: -50px;
  transition: 0.2s ease;
}
.checkout_not .content,
.checkout .content {
  background-color: #b11e88;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  max-width: 1540px;
  width: 100%;
  border-radius: 8px;
  height: 48px;
  padding: 10px;
  color: #fafafa;
}
.checkout_not .content .price,
.checkout .content .price {
  display: flex;
  align-items: center;
  height: 100%;
}
.cart {
  font-weight: 500;
  font-size: 18px;
}
.price .icon {
  width: 28px;
  background-position: right;
  background-size: 28px;
  background-image: url("../../assets/customer/menu/right_w.svg");
}
</style>
