<template>
  <div id="extras_modal" class="modal">
    <div class="extras">
      <div class="fixed">
        <div
          v-if="item.photo"
          class="banner"
          :style="{
            'background-image': 'url(' + item.photo + ')',
          }"
        >
          <button @click="closeClearModal()" class="icon close"></button>
        </div>
        <div class="head" v-if="item.prices">
          <div class="title">
            <button
              v-if="!item.photo"
              @click="closeClearModal()"
              class="icon close"
            ></button>
            <div v-if="item.discount > 0" class="discount">
              {{ "-" + item.discount + "%" }}
            </div>

            <h4>{{ item.title }}</h4>
          </div>

          <div class="price" v-if="item.discount > 0">
            <s>{{ formatPrice(item.prices[0].price) }}</s>
            &nbsp;
            {{
              formatPrice(
                item.prices[0].price -
                  (item.prices[0].price * item.discount) / 100
              )
            }}
          </div>
          <div class="price" v-else>
            {{ formatPrice(item.prices[0].price) }}
          </div>
        </div>
        <div
          v-if="item.description_en || item.description_fi"
          v-html="
            $i18n.locale == 'en' ? item.description_en : item.description_fi
          "
          class="ingredients"
        ></div>
      </div>
      <div id="scroll_" class="scroll">
        <div class="compulsories">
          <div class="sub-compulsories" :id="'compulsories-' + index">
            <div
              v-for="(price, index) in item.prices"
              @click.prevent="addCompulsoryToCart(item, price)"
              :key="index"
              :class="[
                'sub-compulsory',
                isSelectedPrice(price)
                  ? 'active_sub_compulsory'
                  : product.prices.length
                  ? 'off_compulsory'
                  : '',
              ]"
            >
              <div class="option">
                <label :for="'sub_compulsory_' + price.id">
                  {{
                    (price.title ? price.title + ": " : "") +
                    " " +
                    price.description
                  }}</label
                >
                <input
                  type="radio"
                  :name="'compulsory_' + price.id"
                  :id="'sub_compulsory_' + price.id"
                />
              </div>
              <div class="price" v-if="item.discount > 0">
                <s>{{ formatPrice(price.price) }}</s>
                &nbsp;
                {{
                  formatPrice(price.price - (price.price * item.discount) / 100)
                }}
              </div>
              <div v-else class="price">
                {{ formatPrice(price.price) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button @click="done" :class="[completeItem ? '' : 'deactive', 'fill']">
      <div>
        {{ $t("customer_home.add_basket") }}
      </div>
      <div>
        {{
          total_price_item > 0
            ? formatPrice(total_price_item - (total_price_item * item.discount) / 100)
            : formatPrice(0)
        }}
      </div>
    </button>
  </div>
</template>
<script>
export default {
  methods: {
    addCompulsoryToCart(item, price) {
      //make a copy to save
      // let tmp_item = Object.assign({}, item);
      if (this.checkAddedSubCompulsory(price)) {
        this.product.prices = [];
      }

      this.product.prices.push(price);

      this.totalPriceItem();
    },
    added_price_ids() {
      return this.product.prices;
    },
    done() {
      if (this.product.prices.length) {
        this.temp_prices = this.product.prices.slice();

        this.product = Object.assign({}, this.item);

        this.$store.dispatch("addItemToCart", {
          item: this.product,
          quantity: 1,
        });

        this.$store.dispatch("addPricesToCart", {
          item: this.product,
          index: this.$store.state.cart.length - 1,
          prices: this.temp_prices,
        });

        this.closeClearModal();
      }
    },
    totalPriceItem() {
      let total = 0;
      this.product.prices.forEach((element) => {
        total += element.price;
      });

      this.total_price_item = total; // discount should add here

      return;
    },
    checkAddedSubCompulsory(price) {
      if (this.product.prices.length) {
        return !(this.product.prices[0].id == price.id);
      }
    },
    isSelectedPrice(price) {
      if (this.product.prices.length) {
        return price.id == this.product.prices[0].id;
      }
    },
    closeClearModal() {
      this.product = {
        prices: [],
      };

      this.total_price_item = 0;

      this.closeModal("extras_modal");

      this.$router.replace({ query: null }).catch(() => {});
    },
  },
  watch: {
    $route(to, from) {
      // document.getElementById("scroll_").scrollTo(0, 0);
      // this.temp_compulsories = [];
      // this.temp_extras = [];
      // this.temp_item = {
      //   compulsories: [],
      //   extras: [],
      // };
      // this.added_sub_compulsories = [];
      // this.total_price_item = 0;
    },
  },
  computed: {
    completeItem() {
      return this.product.prices.length;
    },
  },
  props: {
    item: {},
    index: 0,
  },
  data() {
    return {
      added_sub_compulsories: [],
      product: {
        prices: [],
      },
      temp_prices: [],
      total_price_item: 0,
    };
  },
};
</script>
<style scoped>
.modal {
  gap: 12px;
  min-height: 90vh;
  background-color: #ffffff;
  height: fit-content;
  max-height: 90vh;
  overflow-y: hidden;
  justify-content: flex-start;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  max-width: 768px;
  margin: 0 auto;
  /* padding: 0; */
  padding: 0 0 85px;
}

.banner {
  width: 100%;
  min-height: 136px;
  border-radius: 8px 0 0;
  background-size: cover;
  background-position: center;
  background-image: url("../../assets/customer/menu/item.png");
  display: flex;
  padding: 12px;
  justify-content: flex-start;
  align-items: flex-start;
}

.extras {
  background-color: #ffffff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  /* max-height: calc(90vh - 77px); */
  position: relative;
}
.extra {
  display: flex;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px 0;
  border-bottom: 1px solid #f5f6f7;
}
.fixed {
  padding: 0;
}
.scroll {
  overflow-y: scroll;
  padding: 0 12px;
}
.head {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
  padding: 12px 12px 8px;
}

.extra-title,
.compulsory-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.extra-title {
  padding-top: 12px;
}
.optional,
.required,
.discount {
  font-weight: 500;
  font-size: 12px;
  color: #b11e88;
  background-color: #ffeeeb;
  border-radius: 8px;
  padding: 4px 8px;
}
.title {
  display: flex;
  gap: 8px;
  width: 68%;
  align-items: center;
}

span.price {
  color: #776282;
  font-size: 14px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ingredients {
  font-weight: 300;
  font-size: 14px;
  color: #b3b3b3;
  margin: 0 12px;
  padding: 0 0 12px;
  border-bottom: 1px solid #f0edf2;
}

.deactive {
  opacity: 0.5;
}
.control {
  display: flex;
  width: auto;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  font-size: 14px;
  color: #776282;
}
div.price {
  display: flex;
  justify-content: flex-end;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #4d4d4d;
}
.icon {
  width: 24px;
  height: 24px;
  background-size: 20px;
  border-radius: 8px;
}

.plus {
  background-size: 24px;
  background-image: url("../../assets/common/unchecked.svg");
}

.minus {
  background-size: 24px;
  background-image: url("../../assets/common/checked_orange.svg");
}
.vegan {
  background-color: transparent;
  background-image: url("../../assets/customer/menu/vegan.svg");
}
.gluten {
  background-color: transparent;
  background-image: url("../../assets/customer/menu/gluten.svg");
}
.lactose {
  background-color: transparent;
  background-image: url("../../assets/customer/menu/lactose.svg");
}

/* .tip {
  background-color: transparent;
  background-size: 28px;
} */

h4 {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.compulsory {
  padding: 14px 0 12px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  border-bottom: 1px solid #f0edf2;
}
.compulsory span {
  color: #b3b3b3;
  font-size: 12px;
}
.sub-compulsories {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  width: 100%;
  height: 136px;
  overflow-y: hidden;
}
.show {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.text {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #b11e88;
}

.arrow {
  background-color: transparent;
  background-image: url("../../assets/customer/menu/down.svg");
}

.sub-compulsory {
  width: 100%;
  border: 1px solid #8f93bf3d;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #776282;
  cursor: pointer;
}

input[type="radio"] {
  appearance: none;
}

.active_sub_compulsory {
  border: 1px solid #b11e88;
  color: #b11e88;
}

.extra h4 {
  font-weight: 400;
  width: auto;
  font-size: 14px;
  line-height: 16px;
  color: #776282;
}

.head h4 {
  color: #4d4d4d;
  width: 60%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  text-align: left;
}

button.fill {
  min-height: 53px;
  margin: 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

button.deactive {
  opacity: 0.4;
}

.change-price {
  background-color: #ffeeeb;
  border-radius: 8px;
  padding: 4px 8px;
  font-weight: 400;
  font-size: 14px;
  color: #b11e88;
}

s {
  font-weight: 400;
  font-size: 10px;
  color: #b3b3b3;
}

.close {
  background-image: url("../../assets/common/close.svg");
  height: 32px;
  width: 32px;
  background-color: #fafafa;
  background-size: 24px;
}

.off_compulsory {
  opacity: 0.3;
}
</style>
