<template>
  <div class="modal" id="restaurant_info">
    <restaurant-photos-carousel
      :photos="restaurant.photos"
    ></restaurant-photos-carousel>
    <h3>{{ restaurant.title }}</h3>
    <div class="description">
      {{ restaurant.about }}
    </div>
    <!-- <restaurant-options
      :wifi="restaurant.wifi"
      :bar="restaurant.bar"
      :smoking="restaurant.smoking"
      :parking="restaurant.parking"
      :wheelchair="restaurant.wheelchair"
      :outdoor="restaurant.outdoor"
    ></restaurant-options> -->
    <restaurant-working-times
      :days="restaurant.shop_hours"
      :delivery_times="restaurant.delivery_hours"
    ></restaurant-working-times>
    <restaurant-contact
      :phone_number="restaurant.phone_number"
      :address="restaurant.address"
      :facebook="restaurant.facebook"
      :instagram="restaurant.instagram"
      :twitter="restaurant.twitter"
      :website="restaurant.website"
      :google_maps="restaurant.google_maps"
      :tiktok="restaurant.tiktok"
    ></restaurant-contact>
  </div>
</template>
<script>
import RestaurantContact from "./RestaurantContact.vue";
import RestaurantOptions from "./RestaurantOptions.vue";
import RestaurantPhotosCarousel from "./RestaurantPhotosCarousel.vue";
import RestaurantWorkingTimes from "./RestaurantWorkingTimes.vue";

export default {
  components: {
    RestaurantPhotosCarousel,
    RestaurantOptions,
    RestaurantWorkingTimes,
    RestaurantContact,
  },
  props: {
    restaurant: {},
  },
};
</script>
<style scoped>
.modal {
  min-height: 500px;
  background-color: #ffffff;
  height: fit-content;
  max-height: 85%;
  overflow-y: scroll;
  gap: 24px;
  justify-content: flex-start;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  max-width: 768px;
  margin: 0 auto;
  padding: 12px 12px 85px;
}

h3 {
  font-size: 18px;
  color: #4d4d4d;
  text-align: left;
}

.description {
  color: #b3b3b3;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
}
</style>