<template>
  <div id="edit_address_modal" class="modal">
    <div class="form">
      <div class="input">
        <label class="deactive" for="title">
          {{ $t("information.title") }}
        </label>
        <input
          :placeholder="$t('cart.for_ex')"
          class="active"
          type="text"
          id="title"
          v-model="address.title"
        />
      </div>

      <div class="input">
        <label class="deactive" for="description">
          {{ $t("account.address") }}*
        </label>
        <textarea
          class="active"
          type="text"
          id="description"
          @change="getCoordinates()"
          v-model="address.description"
        ></textarea>
      </div>

      <div class="input">
        <label class="deactive" for="zipcode">
          {{ $t("information.zip_code") }}*
        </label>
        <input
          class="active"
          type="text"
          id="zipcode"
          @change="getCoordinates()"
          v-model="address.zip_code"
        />
      </div>

      <div class="input">
        <label class="deactive" for="city">
          {{ $t("information.city") }}*
        </label>
        <input
          class="active"
          type="text"
          id="city"
          @change="getCoordinates()"
          v-model="address.city"
        />
      </div>

      <div class="input">
        <label class="deactive" for="door_code">
          {{ $t("information.door_code") }}
        </label>
        <input
          class="active"
          type="text"
          id="door_code"
          @change="getCoordinates()"
          v-model="address.door_code"
        />
      </div>

      <div class="input">
        <label class="deactive" for="phone_number">
          {{ $t("information.phone_number") }}*
        </label>
        <input
          class="active"
          type="text"
          id="phone_number"
          v-model="address.phone_number"
        />
      </div>
    </div>
    <button v-if="!edit" @click="useCurrentLocation()" class="outline">
      {{ "Use my current location" }}
    </button>
    <button @click="submit()" class="fill">
      {{ edit ? $t("account.save_changes") : $t("account.submit_new_address") }}
    </button>
  </div>
</template>
<script>
export default {
  watch: {
    $route(to, from) {
      if (from) {
        if (!this.edit) {
          document.getElementById("edit_address_modal").style.height = "580px";
          this.address.title = "";
          this.address.description = "";
          this.address.door_code = "";
          this.address.zip_code = "";
          this.address.city = "";
          this.address.latitude = "";
          this.address.longitude = "";
          this.address.phone_number = localStorage.phone_number
            ? localStorage.phone_number
            : "";
        } else {
          document.getElementById("edit_address_modal").style.height = "540px";
        }
      }
    },
  },
  methods: {
    submit() {
      if (this.phoneNumberValidation()) {
        if (this.edit) {
          this.updateAddress(this.address);
        } else {
          if (this.address.latitude && this.address.longitude) {
            this.createAddress(this.address);
          } else {
            this.getCoordinates();
            this.createAddress(this.address);

            // this.notFoundAddressError();
          }
        }
      } else {
        this.global_error.show = true;
        this.global_error.message = this.$t("registration.phone_number_error");
      }
    },
    phoneNumberValidation() {
      return (
        /^\d*$/.test(
          this.address.phone_number.substring(
            1,
            this.address.phone_number.length
          )
        ) &&
        (this.address.phone_number.charAt(0) == 0 ||
          this.address.phone_number.substring(0, 4) == "+358") &&
        this.address.phone_number.length >= 7 &&
        this.address.phone_number.length <= 17
      );
    },
    useCurrentLocation() {
      const vm = this;
      if (!localStorage.latitude && !localStorage.longitude) {
        navigator.permissions
          .query({
            name: "geolocation",
          })
          .then(function (result) {
            if (result.state == "denied") {
              vm.global_error.message = "Please access your location";

              vm.global_error.show = true;
            } else {
              vm.whereAreYou();
            }
          });
      } else {
        vm.whereAreYou();

        vm.address.city = localStorage.address_city;
        vm.address.zip_code = localStorage.address_zip_code;
        vm.address.description = localStorage.address_desc;
        vm.address.latitude = localStorage.latitude;
        vm.address.longitude = localStorage.longitude;
        vm.address.phone_number = localStorage.phone_number;
        // vm.createAddress(vm.address);
      }
    },
    getCoordinates() {
      if (
        this.address.description &&
        this.address.zip_code &&
        this.address.city
      ) {
        this.findAddress(
          this.address.description.split(" ").join("+") +
            ",+" +
            this.address.zip_code +
            "+" +
            this.address.city
        );
      }
    },
    findAddress(address) {
      this.$http
        .get(
          this.$hostname + "/client/coordiantes?address=" + address,
          this.token.config
        )
        .then((resp) => {
          this.address.latitude = resp.data.coordinates.lat;
          this.address.longitude = resp.data.coordinates.lng;
        })
        .catch((err) => {
          this.notFoundAddressError();
          // this.showErrors(err);
        });
    },
    notFoundAddressError() {
      this.global_error.show = true;
      this.global_error.message = this.$t("cart.address_not_found");
    },
  },
  props: {
    address: {},
    edit: false,
  },
};
</script>
<style scoped>
.modal {
  height: 500px;
  gap: 12px;
  justify-content: flex-start;
}

.form {
  background-color: #ffffff;
  padding: 12px;
  border-radius: 16px;
}

textarea {
  width: 65%;
}

input#phone_number {
  width: 50%;
}

button.fill {
  background-color: #b11e88;
  text-align: center;
  width: 100%;
  height: 53px;
  border-radius: 16px;
  color: #f8f9fa;
  font-size: 18px;
}
</style>
